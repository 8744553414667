import React from "react";
import { Route, Switch, Redirect } from 'react-router-dom';
import { useDataState } from "../state/context";
import Page from '../view/pages/index';
import Technology from '../view/technologies/index';
import Vacancies from '../view/vacancies/index';
import VacancyDetail from '../view/vacancies/view/index';
import NoFound from '../view/pages/NoFound';
import Shop from '../view/shop/index';
import Cart from '../view/cart/index';
import Checkout from '../view/cart/checkout/index';
import Success from '../view/cart/pages/success';
import Error from '../view/cart/pages/error';
import Cancel from '../view/cart/pages/cancel';
import ProductDetail from '../view/shop/product/index';
import Profile from '../view/user/profile/index';
import Settings from '../view/user/profile/settings';
import Orders from '../view/user/profile/orders';
import Verify from '../view/user/auth/verify';
import Reset from '../view/user/auth/reset';

const Routes = () => {
    const {user, base} = useDataState();

    return (
        <>
            <Switch>
                <Route exact path={"/:lang"} component={Page} />
                <Route exact path={"/:lang/vacancies/:slug"} render={(props) => (<VacancyDetail {...props} Lang={base.currentLang} />)} />
                <Route exact path={"/:lang/vacancies"} render={(props) => (<Vacancies Lang={base.currentLang} />)} />
                <Route exact path={"/:lang/user/settings"} render={(props) => (<Settings Lang={base.currentLang} />)} />
                <Route exact path={"/:lang/user/profile"} render={(props) => (<Profile Lang={base.currentLang} />)} />
                <Route exact path={"/:lang/user/orders"} render={(props) => (<Orders Lang={base.currentLang} />)} />
                <Route exact path={"/:lang/page/:slug"} component={Page} />
                <Route exact path={"/:lang/technology/:slug"} component={Technology} />
                <Route exact path={"/:lang/cart"} component={Cart} />
                <Route exact path={"/:lang/checkout"} component={Checkout} />
                <Route exact path={"/:lang/order/success/:hash"} component={Success} />
                <Route exact path={"/:lang/order/error"} component={Error} />
                <Route exact path={"/:lang/order/cancel"} component={Cancel} />
                <Route exact path={"/:lang/shop/:gen/:cat/:subcat/:slug"} component={ProductDetail} />
                <Route exact path={"/:lang/shop/:gen/:cat/:subcat"} component={Shop} />
                <Route exact path={"/:lang/shop/:gen/:cat/"} component={Shop} />
                <Route exact path={"/:lang/shop/:gen"} component={Shop} />
                <Route exact path={"/:lang/verify-email/:id/:hash"} component={Verify} />
                <Route exact path={"/:lang/reset-password"} component={Reset} />
                <Route exact path="*" component={NoFound} status={404} />
            </Switch>
            { user.redirect ? (<Redirect push to={{pathname: "/" + base.currentLang + "/user/profile/", state: { visible: false }}} />) : null}
        </>
    );
};

export default Routes;