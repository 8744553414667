const validation = (name, value) => {
    const EMAIL_REGEX = new RegExp(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/);
    const PHONE_REGEX = new RegExp(/[^+373s]?[0-9]{2}[-][0-9]{3}[-][0-9]{3}/gm);

    switch (name) {
        case "fio":
            return value.length < 4 ? "Atleast 4 characaters required" : "";
        case "name":
            return value.length < 4 ? "Atleast 4 characaters required" : "";
        case "password":
            return value.length < 3 ? "Atleast 3 characaters required" : "";
        case "confirm_password":
            return value[0] !== value[1] ? "Password doesn't match" : "";
        case "email":
            return EMAIL_REGEX.test(value) ? "":"Email address is invalid";
        case "phone":
            return PHONE_REGEX.test(value) ? "":"Phone number is invalid";
        default:
            break;
    }
};

export {validation}