import React, {lazy, Suspense} from "react";
import {decode} from 'html-entities';
import InnerHTML from 'dangerously-set-html-content';
import StickyBox from "react-sticky-box";
import {isMobile} from 'react-device-detect';
import {Helmet} from "react-helmet";
import {useDataState, useDataDispatch} from "../../state/context";
import {getPage} from "../../state/actions/PageAction";
import  useAsyncDataFetch  from "../Hooks/useAsyncDataFetch";
import NoFound from './NoFound';
import Loader from '../../ui/loader/index';
import Sidebar from './sidebar/index';
import Seo from "../layout/seo";
import Banner from './banner/index';
import styles from './index.module.scss';

const Page = (props) => {
    const {params} = props.match;
    const {slug} = params;
    const {page, base} = useDataState();
    const dispatch = useDataDispatch();
    const ssr = (typeof props.location.state !== 'undefined' && props.location.state.ssr === false) ? false : base.ssr;

    const {isLoading} = useAsyncDataFetch({promiseFn: getPage, dispatch}, ssr, {slug});

    if (!base.success) {
        return <NoFound />;
    }

    if (isLoading && !page.content.support_top && !page.content.support_bottom) return <Loader />;

    const regex = /(<([^>]+)>)/gi;
    const jsonLdDescription = (page.content['text_' + base.currentLang] !== undefined && page.content['text_' + base.currentLang] !== null) ? page.content['text_' + base.currentLang].replace(regex, "") : '';

    const jsonLdPage = {
        "@context": "http://schema.org",
        "@type": "WebPage",
        "name": page.content['title_' + base.currentLang],
        "description": jsonLdDescription,
        "publisher": {
            "@type": "ProfilePage",
            "name": "Sport Spirit"
        }
    };

    return (
        <>
        <Helmet>
            <script type="application/ld+json">{JSON.stringify(jsonLdPage)}</script>
        </Helmet>

        <Seo data={page.content} lang={base.currentLang}/>

        {(page.banners.length > 0) ? Object.entries(page.banners).map(([index, banner]) => (
            <Banner lazy={index} key={index} data={banner} currentLang={base.currentLang}/>
        )) : (
			<>
				{page.content.support_top !== '0' || page.content.support_bottom !== '0' ? (
                    <div className={styles.support}>
                        {isMobile || base.mobile ? (
                            <Sidebar Lang={base.currentLang} data={page.menu} slug={slug}/>
                        ) : (
                            <StickyBox offsetTop={100} offsetBottom={0}>
                                <Sidebar Lang={base.currentLang} data={page.menu} slug={slug}/>
                            </StickyBox>
                        )}


                        <div className={styles.support_content}>
                            {(!base.success || isLoading) ? (
                                <Loader />
                            ) : (
                                <>
                                <h2>{page.content['title_' + base.currentLang]}</h2>

                                <div className={styles.support_content_text}>
                                    {page.content['text_' + base.currentLang] !== null ? (
                                        <InnerHTML key={'text' + base.currentLang + page.content['id']}
                                                   html={decode(page.content['text_' + base.currentLang])}/>
                                    ) : null}
                                </div>
                                </>
                            )}
                        </div>
                    </div>
                ) : (
                    <div className={styles.page}>
                        {Object.keys(page.content).length > 0 && decode(page.content['text_' + base.currentLang]).length > 0 ? (
                            <InnerHTML key={'text' + base.currentLang + page.content['id']} html={decode(page.content['text_' + base.currentLang])} />
                        ):null}
                    </div>
                )}
			</>
		)}
        </>
    );
};

export default Page;
