import React, {useEffect} from 'react';
import i18n from 'i18next';
import { useDataDispatch } from "../../../../state/context";
import {setLang} from "../../../../state/actions/BaseAction";
import styles from './index.module.scss';

const LangButtons = (props) => {
    const dispatch = useDataDispatch();

    function set(Lang){
        setLang({Lang, dispatch});
    }

    useEffect(() => {
        i18n.changeLanguage(props.currentLang);
    }, [props.currentLang])

	useEffect(() => {
		localStorage.setItem('i18nextLng', props.currentLang);
		i18n.changeLanguage(props.currentLang);
    }, [])

    return (
        <div className={`${styles.items} ${typeof props.mobile !== 'undefined' && props.mobile ? styles.mobile:''}`}>
            {Object.entries(props.langs).map(([index, item]) => (
                <button className={`${styles.items_item} ${(item.url === props.currentLang) ? styles.items_item_active : ''}`}
                        key={item.id} onClick={() => set(item.url)}>
                    {item.url}
                </button>
            ))}
        </div>
    )
}


export default LangButtons