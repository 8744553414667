import React, {useRef, useState, useEffect, lazy, Suspense} from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {useDataState} from "../../../state/context";
import {useDataDispatch} from "../../../state/context";
import {recountCart, hiddeMiniCart, showMiniCart} from "../../../state/actions/CartAction";
import HomeIcon from'../../../ui/icons/home';
import SearchIcon from'../../../ui/icons/search';
import CartIcon from'../../../ui/icons/cart';
import SettingsIcon from'../../../ui/icons/settings';
import Settings from'../../elements/mobile/footer/settings/index';
import Search from'../../elements/mobile/footer/search/index';
import Cart from'../../elements/mobile/footer/cart/index';
import styles from './index.module.scss';

const MobileFooter = () => {
	const {t} = useTranslation();
    const dispatch = useDataDispatch();
    const {base, shop, cart} = useDataState();
    const count = Object.keys(cart.products).length + Object.keys(cart.certificates).length;
    const [settings, setSettings] = useState(false);
    const [searchToggle, setSearchToggle] = useState(false);
    const settingsBlock = useRef(null);
    const searchBlock = useRef(null);
    const cartBlock = useRef(null);

    const toggleSettings = () => {
        if (settings) setSettings(false);
        else setSettings(true);

        setSearchToggle(false);
        hiddeMiniCart(dispatch);
    }

    const toggleSearch = () => {
        if (searchToggle) setSearchToggle(false);
        else setSearchToggle(true);

        setSettings(false);
        hiddeMiniCart(dispatch);
    }

    const toggleCart = () => {
        if (cart.toggle) {
			hiddeMiniCart(dispatch);
        }else{
			showMiniCart(dispatch);
		};

        setSearchToggle(false);
        setSettings(false);
    }

    const handleClickOutside = (event) => {
        if (settingsBlock.current && !settingsBlock.current.contains(event.target)) {
            setSettings(false);
        }

        if (searchBlock.current && !searchBlock.current.contains(event.target)) {
            setSearchToggle(false);
        }

        if (cartBlock.current && !cartBlock.current.contains(event.target)) {
			hiddeMiniCart(dispatch);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);

        recountCart(dispatch);
    }, [dispatch]);

    return (
		<div className={styles.f_wrapper}>
          <div className={styles.copyright}>
            <span className={styles.copyright_text}>{ t('We accept') }: <img className={styles.copyright_image} src="/social_mastercard-64.png" alt="" /> <img className={styles.copyright_image} src="/social_visa-64.png" alt="" /></span>
          </div>
          <div className={styles.footer}>
              <ul className={styles.footer_items}>
                  <li className={styles.footer_items_item}>
                      <a href={"/" + base.currentLang}>
                          <HomeIcon name="discount" color="#fff" size={22}/>
                      </a>
                  </li>
                  <li ref={searchBlock} className={styles.footer_items_item}>
                      <span onClick={toggleSearch}>
                          <SearchIcon name="discount" color="#fff" size={22}/>
                      </span>
                      <Search search={shop.search} currentLang={base.currentLang} searchToggle={searchToggle}
                              setSearchToggle={setSearchToggle}/>
                  </li>
                  <li ref={cartBlock} className={styles.footer_items_item}>
                      <span className={styles.footer_items_item_cart}>{count}</span>
                      <span onClick={toggleCart}>
                          <CartIcon name="discount" color="#fff" size={22}/>
                      </span>
                      <Cart toggle={cart.toggle} toggleCart={toggleCart} count={count} products={cart.products}
                            certificates={cart.certificates} currentLang={base.currentLang}/>
                  </li>
                  <li ref={settingsBlock} className={styles.footer_items_item}>
                      <span onClick={toggleSettings}>
                          <SettingsIcon name="discount" color="#fff" size={22}/>
                      </span>
                      <Settings settings={settings} setSettings={setSettings} currentLang={base.currentLang}
                                langs={base.langs} headerPages={base.headerPages} footerMenu={base.footerMenu}/>
                  </li>
              </ul>
          </div>
		</div>
    );
}

export default MobileFooter