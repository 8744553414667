import constants from "../constants";
import StorageService from "../storage/index";
import {messages} from './FormAction';
import {encrypt, decrypt} from "../encrypt";
import axios from "axios";

axios.defaults.baseURL = constants.API_URL;

const showMiniCart = (dispatch) => {
    dispatch({type: constants.SHOW_MINI_CART});
}

const hiddeMiniCart = (dispatch) => {
    dispatch({type: constants.HIDDE_MINI_CART});
}

const setDelivery = (dispatch, deliveryType, regionId, payment) => {
    recountCart(dispatch, deliveryType, regionId, payment);
}

const recountCart = (dispatch, deliveryType = false, regionId = false, payment = false) => {
    const products = StorageService.get('products');
    const certificates = StorageService.get('certificate');
	const card = StorageService.get('aBX32gTf');
	let payload = {
      products: {},
      certificates: {},
      baseTotal: 0,
      total: 0,
      sales: 0,
      delivery: 0
    };

    if (products || certificates) {
        const productsData = {
            delivery_type: deliveryType,
            region_id: regionId,
            payment: payment,
            products: products,
            certificates: certificates,
			card: (typeof card !== 'undefined' && card !== null) ? card.barcode:null
        };

        axios.post(constants.API_URL + '/cart/products', {data: encrypt(JSON.stringify(productsData))}).then(resultData => {
            const response = decrypt(resultData.data);

			if(StorageService.get('products') !== null) {
              payload = {
                  products: response.data.products,
                  certificates: {},
                  baseTotal: response.data.cartData.baseTotal,
                  total: response.data.cartData.total,
                  sales: response.data.cartData.sales,
                  delivery: response.data.cartData.delivery
              };
			}

            dispatch({ payload, type: constants.CART_DATA })
        });
    } else {

        dispatch({ payload, type: constants.CART_DATA })
    }

}

const productChangeQuantity = ({slug, barcode, quantityMount, dispatch}) => {
    const products = StorageService.get('products');
    products[barcode]['count'] = quantityMount;

    StorageService.set("products", products);
    recountCart(dispatch);
}

const productRemove = ({removeBarcode, dispatch}) => {
    const products = StorageService.get('products')
    delete products[removeBarcode];

    StorageService.set("products", products);
    recountCart(dispatch);
}

const addToCart = ({slug, productSize, quantity, dispatch}) => {
    if (productSize) {
        const cartItems = StorageService.get('products') !== 'undefined' ? StorageService.get('products') : {};

        if (cartItems) {
            if (cartItems[slug + productSize.barcode]) {
                cartItems[slug + productSize.barcode]['count'] = cartItems[slug + productSize.barcode]['count'] + parseInt(quantity);
            } else {
                cartItems[slug + productSize.barcode] = {count: quantity, size: productSize.size, slug: slug, size_uid: productSize.size_uid}
            }

            StorageService.set("products", cartItems);
        } else {
            const items = {
                [slug + productSize.barcode]: {
                    count: quantity,
                    size: productSize.size,
 					size_uid: productSize.size_uid,
                    slug: slug
                }
            };
            StorageService.set("products", items);
        }

        recountCart(dispatch);
        showMiniCart(dispatch);
    } else {
        //dispatch({type: constants.ADD_TO_CART_ERROR})
    }
};

const certificateRemove = ({removeBarcode, dispatch}) => {
    const certificates = StorageService.get('certificate')
    delete certificates[removeBarcode];

    StorageService.set("certificate", certificates);
    recountCart(dispatch);
}

const addToCartCertificat = (form, price, dispatch) => {
    const certificateItems = StorageService.get('certificate');
    form.price = (form.price) ? form.price : price;

    if (certificateItems) {
        certificateItems[form.price + form.recipient_name] = form;
        StorageService.set("certificate", certificateItems);
    } else {
        const items = {
            [form.price + form.recipient_name]: form
        };
        StorageService.set("certificate", items);
    }

    recountCart(dispatch);
    showMiniCart(dispatch);
}

const sendCart = ({data, dispatch}) => {
	data.append('goods', JSON.stringify(StorageService.get('products')));
	const jsonObject = Object.fromEntries(data);

	return axios.get(constants.API_BASE_URL + '/sanctum/csrf-cookie', {withCredentials: true}).then(() => {
      axios.post(constants.API_URL + '/order', {data: encrypt(JSON.stringify(jsonObject))}).then(resultData => {
      	const response = decrypt(resultData.data);
		
		if (response.success) {
			if(typeof response.data.redirect !== 'undefined') {
				window.location.replace(response.data.redirect);
			} else {
				StorageService.remove('products');
              window.location = "/order/success/" + response.data.order;
			}
		}else{

			messages(dispatch, 'sendOrderForm', response.success, response.data, 'left');
		}
      });
    });
};

const applyCard = ({barcode, dispatch}) => {

	return axios.get('/apply-card/' + barcode).then(resultData => {
        const result = decrypt(resultData.data);
        const card = result;

		if(card.data.card) {
			StorageService.set("aBX32gTf", {barcode});
			recountCart(dispatch);
		}else{
			StorageService.set("aBX32gTf", {barcode: null});
			console.log('error');
		}
    });
}

export {
    addToCart,
	applyCard,
    productChangeQuantity,
    productRemove,
    addToCartCertificat,
    certificateRemove,
    recountCart,
    showMiniCart,
    hiddeMiniCart,
    setDelivery,
    sendCart
};