import React, {useState} from 'react';
import queryString from 'query-string';
import {useTranslation} from 'react-i18next';
import {newPassword} from "../../../state/actions/UserAction";
import {useDataDispatch} from "../../../state/context";
import {showBtnLoader} from "../../../state/actions/FormAction";
import Button from '../../../ui/form/button/index';
import Input from '../../../ui/form/input/index';
import styles from './index.module.scss';

const Reset = (props) => {
	const {t} = useTranslation();
    const getParams = queryString.parse(props.location.search);
    const {token} = getParams;
    const dispatch = useDataDispatch();
    const [form, setForm] = useState({email: '', password: '', confirm_password: ''});

    const changeHandler = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        setForm({...form, [name]: value});
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const data = new FormData(e.target);

		showBtnLoader(dispatch, 'passwordResetForm', true);
        newPassword({dispatch, data});
    }

    return (
        <div className={styles.verify}>
            <form className={styles.verify_form} onSubmit={handleSubmit}>
                <div className={styles.verify_form_row}>
                  <div className={styles.verify_form_row_col}>
                        <Input name="email" type="email" value={form.email}
                               label={t('email')} changeVal={changeHandler} required={true}/>
                    </div>
                    <div className={styles.verify_form_row_col}>
                        <input type="hidden" name="token" value={token} />
                        <Input name="password" type="password" value={form.password} label={t('new_password')}
                               changeVal={changeHandler} required={true} />
                    </div>
                    <div className={styles.verify_form_row_col}>
                        <Input name="confirm_password" type="password" value={form.confirm_password}
                               label={t('confirm_new_password')} changeVal={changeHandler} required={true}/>
                    </div>
                    <div className={styles.verify_form_row_colfull}>
                      	<Button formName="passwordResetForm" type="submit" text={t("send")}/>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default Reset