import constants from "../constants";

const baseState = {
    success: true,
    langs: [],
	brands: [],
    regions: {},
    stores: {},
    defaultLang: 'kz',
    currentLang: 'kz',
    headerMenu: {},
    footerMenu: [],
    headerPages: [],
    news: [],
	brands: [],
	searchValue: '',
    ssr: true
};

const BaseReducer = (state, action) => {
    const {type, payload} = action;

    switch (type) {
        case constants.SET_LANG: {
            return {
                ...state,
                currentLang: payload.currentLang
            };
        }
        case constants.SET_SSR: {
            return {
                ...state,
                ssr: payload.ssr
            };
        }
        case constants.SET_ERROR: {
            return {
                ...state,
                success: payload
            };
        }
        default:
            return {...state};
    }
};

export {
    baseState,
    BaseReducer
};