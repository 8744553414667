import {decrypt, encrypt} from '../encrypt';

class StorageService {
    get(key) {
		const data = (localStorage.getItem(key) !== null) ? decrypt(localStorage.getItem(key)):null;

		if(data !== null) {
          const CurrentDate = new Date().getTime();
          const ExpireDate = new Date(data.expire).getTime();

          if(CurrentDate > ExpireDate) {
              localStorage.removeItem(key);
              return null;
          }else{
			  delete data.expire;

              return data;
          }
		}

		return null;
    }

    set(key, value) {
        let date = new Date();
        date.setTime(date.getTime() + (60 * 24 * 3) * 60 * 1000);
		value['expire'] = date;

        localStorage.setItem(key, encrypt(value));
    }

    remove(key) {
        localStorage.removeItem(key);
    }
}

export default new StorageService();