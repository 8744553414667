import React from "react";
import { Link } from 'react-router-dom'
import styles from './index.module.scss';

const Collections = (props) => {
    const params = props.match.params

    return (
        <nav className={styles.menu}>
            <ul className={styles.menu_items}>
                {props.items.map((item, key) => (
                    <li key={key} className={styles.menu_items_item}>
                        <Link className={`${styles.menu_items_item_link} ${params.cat === item.slug ? styles.active:''}`}
                              to={'/'+props.currentLang+'/shop/'+params.gen+'/'+item.slug}>{item['title_'+props.currentLang]}</Link>
                    </li>
                ))}
            </ul>
        </nav>
    );
}

export default Collections