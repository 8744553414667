import React, {useState} from "react";
import {useTranslation} from 'react-i18next';
import {useDropzone} from 'react-dropzone';
import {Helmet} from "react-helmet";
import { useDataState, useDataDispatch } from "../../../state/context";
import  useAsyncDataFetch  from "../../Hooks/useAsyncDataFetch";
import { getPost, sendForm } from "../../../state/actions/VacancyAction";
import {showBtnLoader} from "../../../state/actions/FormAction";
import {validation} from "../../../ui/form/validaion/index";
import Loader from '../../../ui/loader/index';
import Input from '../../../ui/form/input/index';
import Textarea from '../../../ui/form/textarea/index';
import File from '../../../ui/form/file/index';
import Button from '../../../ui/form/button/index';
import NoFound from '../../pages/NoFound';
import Seo from "../../layout/seo";
import constants from "../../../state/constants";
import styles from './index.module.scss';

const VacancyDetail = (props) => {
    const {t} = useTranslation();
    const { params } = props.match;
    const { slug } = params;
    const {vacancies, base} = useDataState();
    const dispatch = useDataDispatch();
    const [formErrors, setFormErrors] = useState({});
    const [form, setForm] = useState({
        fio: '',
        phone: '',
        email: '',
        comment: '',
		pdf: false
    });

    const changeHandler = (e) => {
        const name = e.target.name;

        if(name === 'pdf') {
            let fileInput = e.target;
            let filePath = fileInput.value;

            const allowedExtensions = /(\.doc|\.docx|\.odt|\.pdf|\.tex|\.txt|\.rtf|\.wps|\.wks|\.wpd)$/i;

            if (!allowedExtensions.exec(filePath)) {
                alert(t('Invalid file type'));
            }else{
                const file = fileInput.files[0];
                setForm({...form, ['pdf']: file.name});

            }
        }else{
            const value = e.target.value;

            setFormErrors({...formErrors, [name]: validation(name, value)});
            setForm({...form, [name]: value});
        }
    };

	const sendHandler = (e) => {
		e.preventDefault();
		const data = new FormData(e.target);
		data.append("vacancy", vacancies.post['title_' + props.Lang]);

		showBtnLoader(dispatch, 'vacanciViewForm', true);
        sendForm({data, dispatch});
    };

    const { isLoading } = useAsyncDataFetch({ promiseFn: getPost, dispatch }, base.ssr, {slug});
    if (isLoading) return <Loader />;

    if(!base.success) {
        return <NoFound />;
    }

	const regex = /(<([^>]+)>)/gi;
    const jsonLdDescription = (vacancies.post['text_' + base.currentLang] !== undefined && vacancies.post['text_' + base.currentLang] !== null) ? vacancies.post['text_' + base.currentLang].replace(regex, ""):'';

	const jsonLdPage = {
        "@context": "http://schema.org",
        "@type": "WebPage",
        "name":  vacancies.post['title_' + base.currentLang],
        "description": jsonLdDescription,
        "publisher": {
            "@type": "ProfilePage",
            "name": "Sport Spirit"
        }
    };

    return (
        <>
			<Helmet>
                <script type="application/ld+json">{JSON.stringify(jsonLdPage)}</script>
            </Helmet>
            <Seo data={vacancies.post} lang={base.currentLang} />

            <div className={styles.view}>
              	<div className={styles.view__content}>
                  <div className={styles.view__content_text}>
                      {typeof vacancies.post === 'object' ? (
                          <>
                          <div className={styles.view__content_text_thumbnails}>
                              <picture>
                                  <source
                                      srcSet={constants.API_BASE_URL + '/uploads/posts/webp/450-450' + vacancies.post.image + '.webp'}
                                      type="image/webp"/>
                                  <source
                                      srcSet={constants.API_BASE_URL + '/uploads/posts/jpg/450-450' + vacancies.post.image + '.jpg'}
                                      type="image/jpeg"/>
                                  <img loading="lazy" alt={vacancies.post['title_' + props.Lang]}
                                       src={constants.API_BASE_URL + '/uploads/posts/jpg/450-450' + vacancies.post.image + '.jpg'}/>
                              </picture>
                          </div>
                          <div className={styles.view__content_text_title}>
                              <h1>{vacancies.post['title_' + props.Lang]}</h1>
                          </div>
                          <div className={styles.view__content_text_data} dangerouslySetInnerHTML={{ __html: vacancies.post['text_' + props.Lang] }} />
                          </>
                      ):null}
                  </div>
				</div>
                <div className={styles.view__aside}>
                  	<form className={styles.view__aside_form} onSubmit={sendHandler}>
                      <h3>{t('vacancy_form_title')}</h3>
                      <div className={styles.view__aside_form_group}>
                        <h4>{t('vacancy_form_sub_title')}</h4>
                        <div className={styles.view__aside_form_group_row}>
                          <Input name="fio" changeVal={changeHandler} error={formErrors.fio} value={form.fio} label={t('vacancy_form_fio')} />
                          <Input name="phone" changeVal={changeHandler} error={formErrors.phone} value={form.phone} label={t('vacancy_form_phone')} />
                        </div>
                        <div className={styles.view__aside_form_group_row}>
                          <Input name="email" changeVal={changeHandler} error={formErrors.email} value={form.email} label={t('vacancy_form_email')} />
                          <Input name="birthday" changeVal={changeHandler} type="date" error={formErrors.birthday} value={form.birthday} label={t("birthday")}/>
                        </div>
                        <Textarea name="comment" changeVal={changeHandler} label={t('vacancy_form_about')} />
                        <File label={form.pdf ? form.pdf:t('vacancy_form_pdf')} name="pdf" changeVal={changeHandler} />
                        <div className={styles.view__aside_form_group_btn}>
                          <Button formName="vacanciViewForm" type="submit" text={t("vacancy_form_send")}/>
                        </div>
                      </div>
                    </form>
                    {
                        vacancies.related && vacancies.related.length > 0 ? (
                            <>
                            <h3>{t("other_vacancies")}</h3>
                            { vacancies.related.map((item, key) => (
                                <a key={key} href={'/' + props.Lang + '/vacancies/' + item.slug} className={styles.view__aside_item}>
                                    <div className={styles.view__aside_item_thumbnail}>
                                      	<picture>
                                            <source
                                                srcSet={constants.API_BASE_URL + '/uploads/posts/webp/450-450' + item.image + '.webp'}
                                                type="image/webp"/>
                                            <source
                                                srcSet={constants.API_BASE_URL + '/uploads/posts/jpg/450-450' + item.image + '.jpg'}
                                                type="image/jpeg"/>
                                            <img loading="lazy" alt={item['title_' + props.Lang]}
                                                 src={constants.API_BASE_URL + '/uploads/posts/jpg/450-450' + item.image + '.jpg'}/>
                                        </picture>
                                    </div>
                                    <h2>{item['title_' + props.Lang]}</h2>
                                </a>
                            ))}
                            </>
                        ): null
                    }
                </div>
            </div>
        </>
    );
};

export default VacancyDetail;
