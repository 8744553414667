import React, {useEffect, useState} from 'react';
import styles from './index.module.scss';

const Sizes = (props) => {
	const changeHandler = (value) => {
        props.data.setProductSize(value);
    }

	useEffect(() => {
        if (props.size.length === 1) {
			props.data.setProductSize({barcode: props.size[0].barcode, size: props.size[0].size_eu, size_uid: props.size[0].size_uid});
        }else{
			props.data.setProductSize(null);
		}
    }, []);

    return (
        <div className={styles.wrapper}>
          	<div className={styles.wrapper_items}>
          	{props.size.map((size) => (
              <div className={`${styles.wrapper_items_item} ${props.data.productSize !== null && (size.barcode === props.data.productSize.barcode || props.size.length === 1) ? styles.wrapper_items_item_active:''}`} key={size.barcode} onClick={() => changeHandler({barcode: size.barcode, size: size.size_eu, size_uid: size.size_uid})}>{size.size_eu}</div>
             ))}
          </div>
        </div>
    );
}

export default Sizes