import React from 'react';
import {useDataState} from "../../../state/context";
import styles from './index.module.scss';

const Select = ({changeVal, name, label, defaultOption, data, type, active}) => {
    const {base} = useDataState();

    let selected = false
    const changeHandler = (e) => {
        changeVal(e)
    }

    if (typeof active !== 'undefined')
        selected = typeof active.parent_id !== 'undefined' && active.parent_id > 0 ? active.parent_id : active

    if (typeof selected === 'object')
        selected = selected.id;

    return (
        <div className={styles.item}>
            <label className={styles.item_label}>{label}</label>
            <select onChange={(e) => changeHandler(e)} className={styles.item_select} name={name} value={selected}>
                {defaultOption ? (
                    <option value={ null }>{ defaultOption }</option>
                ) : null}

                {type && type === 'express' ? (
                    <>
                    {Object.entries(data).map(([key, val]) => (
                        <>
                        {val.express_price > 0 && val.parent_id === 0 ? (
                            <option key={key} value={ val.id }>{val['title_' + base.currentLang]}</option>
                        ) : null}
                        </>
                    ))}
                    </>
                ) : type && type === 'vacancy' ? (
                    <>
                    {Object.entries(data).map(([key, val]) => {
                        return (
                            <option key={key} value={ val['title_' + base.currentLang] }>{val['title_' + base.currentLang] }</option>
                        )
                    })}
                    </>
                ): (
                    <>
                    {Object.entries(data).map(([key, val]) => {
                        const title = (typeof val['title_' + base.currentLang] !== 'undefined') ? val['title_' + base.currentLang] : val['name_' + base.currentLang];
          
                        return (
                            <option key={key}
                                    value={ typeof val.url === 'undefined' ? val.id : val.url }>{ typeof title === 'undefined' ? val.url : title }</option>
                        )
                    })}
                    </>
                )}
            </select>
        </div>
    );
}

export default Select