import constants from "../constants";
import {setNotFound} from "./BaseAction";
import {decrypt} from "../encrypt";
import axios from "axios";

axios.defaults.baseURL = constants.API_URL;

const getTechnology = ({slug, dispatch}) => {
    slug = (typeof slug === 'undefined') ? 'home' : slug;

    return axios.get('/technology/' + slug).then(resultData => {
        const result = decrypt(resultData.data);
        const payload = result;

		if(!payload.success) setNotFound(payload.success, dispatch);
		else dispatch({payload, type: constants.GET_TECHNOLOGY});
    })
};

export {getTechnology};
