import React, {useState, useEffect} from 'react';
import {Redirect} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import Cookies from "../../../state/coockies/index";
import {useDataState, useDataDispatch} from "../../../state/context";
import  useAsyncDataFetch  from "../../Hooks/useAsyncDataFetch";
import {showBtnLoader} from "../../../state/actions/FormAction";
import {getUser, updateUser} from "../../../state/actions/UserAction";
import Input from '../../../ui/form/input/index';
import Select from '../../../ui/form/select/index';
import Sidebar from './sidebar/index';
import Button from '../../../ui/form/button/index';
import Loader from '../../../ui/loader/index'
import styles from './index.module.scss';

const Settings = (props) => {
    const {t} = useTranslation();
    const {user, base} = useDataState();
    const [form, setForm] = useState(user);
    const dispatch = useDataDispatch();

    const changeHandler = (e) => {
        user.success = null;
        const name = e.target.name;
        const value = e.target.value;

        setForm({...form, [name]: value});
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const data = new FormData(e.target);

		showBtnLoader(dispatch, 'userSettingsForm', true);
        updateUser({dispatch, data, type: 'userSettingsForm'});
    }

    const {isLoading} = useAsyncDataFetch({promiseFn: getUser, dispatch}, false, {});

    useEffect(() => {
        setForm(user);
    }, [isLoading]);


	if (typeof Cookies.get('token') === 'undefined' || user.token !== Cookies.get('token')) {
        return (
            <Redirect push to={{pathname: "/" + base.currentLang, state: { ssr: false }}} />
        )
    }

    return (
        <div className={styles.main}>
            <div className={styles.main_title}>
                <h1>{t('profile')} <span>{user.name} {user.secondname}</span></h1>
            </div>

            <Sidebar Lang={props.Lang} card={user.card}/>

            <div className={styles.content}>
                {isLoading ? (
                    <Loader />
                ) : (
                    <form className={styles.content_form} onSubmit={handleSubmit}>
                        <div className={styles.content_form_row}>
                            <div className={styles.content_form_row_col}>
                                <Select changeVal={changeHandler} name="lang" label={t('select_language')}
                                        defaultOption=""
                                        data={base.langs}
                                        active={form.lang}/>
                            </div>
                            <div className={styles.content_form_row_colfull}/>
                            <div className={styles.content_form_row_col}>
                                <Input name="password" type="password" value={form.password} label={t('new_password')}
                                       changeVal={changeHandler} required={false}/>
                            </div>
                            <div className={styles.content_form_row_col}>
                                <Input name="confirm_password" type="password" value={form.confirm_password}
                                       label={t('confirm_new_password')} changeVal={changeHandler} required={false}/>
                            </div>
                          	<div className={styles.content_form_row_colfull} />
                            <div className={styles.content_form_row_colbtn}>
                              	<Button formName="userSettingsForm" type="submit" text={t("send")}/>
                            </div>
                        </div>
                        <div className={styles.content_form_static}>

                        </div>
                    </form>
                )}
            </div>

        </div>
    )
}

export default Settings
