import React, {useState, useEffect} from 'react';
import {isMobile} from 'react-device-detect';
import {Redirect} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import Cookies from "../../../state/coockies/index";
import {useDataState, useDataDispatch} from "../../../state/context";
import  useAsyncDataFetch  from "../../Hooks/useAsyncDataFetch";
import {getUser, updateUser} from "../../../state/actions/UserAction";
import {showBtnLoader} from "../../../state/actions/FormAction";
import Input from '../../../ui/form/input/index';
import Select from '../../../ui/form/select/index';
import Textarea from '../../../ui/form/textarea/index';
import Sidebar from './sidebar/index';
import Loader from '../../../ui/loader/index';
import Button from '../../../ui/form/button/index';
import styles from './index.module.scss';

const Profile = (props) => {
    const {t} = useTranslation();
    const {user, base} = useDataState();
    const [form, setForm] = useState(user);
    const dispatch = useDataDispatch();

    const changeHandler = (e) => {
        const name = e.target.name
        const value = e.target.value

        setForm({...form, [name]: value})
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const data = new FormData(e.target);

		showBtnLoader(dispatch, 'userUpdateForm', true);
        updateUser({dispatch, data, type: 'userUpdateForm'});
    }

    const {isLoading} = useAsyncDataFetch({promiseFn: getUser, dispatch}, false, {});

    useEffect(() => {
        setForm(user);
    }, [isLoading]);

    if (typeof Cookies.get('token') === 'undefined' || user.token !== Cookies.get('token')) {
        return (
            <Redirect push to={{pathname: "/" + base.currentLang, state: {ssr: false}}}/>
        )
    }

    const getRegionChilds = (regions, id, name) => {
        const region = getRegionById(regions, id);

        if(typeof region[0] !== 'undefined' && region[0].childs.length > 0) {
            return (
                <Select changeVal={changeHandler} name={name} label="Сектор"
                        data={base.regions[region[0].id]['childs']}
                        active={form[name]}/>
            );
        } else if (typeof region[0] !== 'undefined' && region[0].parent_id !== 0) {
            return (
                <Select changeVal={changeHandler} name={name} label="Сектор"
                        data={base.regions[region[0].parent_id]['childs']}
                        active={form[name]}/>
            );
        } else {
            return null;
        }
    }

    return (
        <div className={styles.main}>
            <div className={styles.main_title}>
                <h1>{t('profile')} <span>{user.name} {user.secondname}</span></h1>
            </div>

            <Sidebar Lang={props.Lang} card={user.card}/>

            <div className={styles.content}>
                {isLoading ? (
                    <Loader />
                ) : (
                    <>
                    {
                        user.card !== null ? (
                            <div className={styles.content_header}>
                                <div className={styles.content_header_item}>
                                <span
                                    className={styles.content_header_item_label}><span>{t('number')}</span> {t('bonus_card')}</span>
                                    <span className={styles.content_header_item_card}>{user.card.barcode}</span>
                                </div>
                                <div className={styles.content_header_item}>
                                    <span className={styles.content_header_item_label}>{t('card_old_discount')}</span>
                                    <span className={styles.content_header_item_discound}>{user.card.sale_sale}%</span>
                                </div>
                                <div className={styles.content_header_item}>
                                    <span className={styles.content_header_item_label}>{t('card_new_discount')}</span>
                                    <span
                                        className={styles.content_header_item_discound_old}>{user.card.sale_new}%</span>
                                </div>
                            </div>
                        ) : null
                    }

                    <form className={styles.content_form} onSubmit={handleSubmit}>
                        <div className={styles.content_form_row}>
                            <div className={styles.content_form_row_col}>
                                <Input name="name" value={form.name} label={t('name')} changeVal={changeHandler}/>
                            </div>
                            <div className={styles.content_form_row_col}>
                                <Input name="lastname" value={form.secondname} label={t('secondname')}
                                       changeVal={changeHandler}/>
                            </div>
                          	<div className={styles.content_form_row_col}>
                                <Input name="surname" value={form.surname} label={t('surname')}
                                       changeVal={changeHandler}/>
                            </div>
                            <div className={styles.content_form_row_col}>
                                <Input name="email" type="email" value={form.email} label={t('email')}
                                       changeVal={changeHandler}/>
                            </div>
                            <div className={styles.content_form_row_col}>
                                <Input name="street" value={form.street} label={t('street')} changeVal={changeHandler}/>
                            </div>
                            <div className={styles.content_form_row_col}>
                                <Input name="home" value={form.home} label={t('house')} changeVal={changeHandler}/>
                            </div>
                            <div className={styles.content_form_row_colhalfe}>
                                <Select changeVal={changeHandler} name="region_id" label={t('region')}
                                        defaultOption={t('select_region')} data={base.regions} type={false}
                                        active={base.regions[form.region_id]}/>
                            </div>
                            <div className={styles.content_form_row_colhalfe}>
                                {getRegionChilds(base.regions, form.region_id, 'sector_id')}
                            </div>

                            {user.type === 'iur' ? (
                                <div className={styles.content_form_row_colfull}>
                                    <div className={styles.content_form_aditional}>
                                        <h3>{t('legal_address')}</h3>
                                        <div className={styles.content_form_row}>
                                            <Input type="text" name="company_name" width="full"
                                                   changeVal={changeHandler} value={form.company_name}
                                                   label={t('company_name')}/>
                                            <Input type="text" name="bank_code"
                                                   width={isMobile || base.mobile ? 'full' : 'half'}
                                                   changeVal={changeHandler}
                                                   value={form.bank_code} label={t('bank_code')}/>
                                            <Input type="text" name="iban"
                                                   width={isMobile || base.mobile ? 'full' : 'half'}
                                                   changeVal={changeHandler}
                                                   value={form.iban} label={t('iban')}/>
                                            <Input type="text" name="company_address" width="full"
                                                   changeVal={changeHandler} value={form.company_address}
                                                   label={t('legal_address')}/>
                                            <Input type="text" name="fiscal_code"
                                                   width={isMobile || base.mobile ? 'full' : 'half'}
                                                   changeVal={changeHandler}
                                                   value={form.fiscal_code} label={t('fiscal_code')}/>
                                            <Input type="text" name="nds_code"
                                                   width={isMobile || base.mobile ? 'full' : 'half'}
                                                   changeVal={changeHandler}
                                                   value={form.nds_code} label={t('nds_code')}/>
                                            <Input type="email" width={isMobile || base.mobile ? 'full' : 'half'}
                                                   name="company_email"
                                                   changeVal={changeHandler} value={form.company_email}
                                                   label={t('email')}/>
                                            <Input type="text" width={isMobile || base.mobile ? 'full' : 'half'}
                                                   name="company_phone"
                                                   changeVal={changeHandler} value={form.company_phone}
                                                   label={t('company_phone')}/>
                                        </div>
                                    </div>
                                </div>
                            ) : null}

                            <div className={styles.content_form_row_colfull}>
                            <Textarea name="comment" value={form.comment} label={t('comment')}
                                      changeVal={changeHandler}/>
                            </div>
                            <div className={styles.content_form_row_colbtn}>
								<Button formName="userUpdateForm" type="submit" text={t("send")}/>
                            </div>
                        </div>
                        <div className={styles.content_form_static}>
                            <div className={styles.content_form_static_item}>
                                <span className={styles.content_form_static_item_name}>{t('phone')}</span>
                                <span className={styles.content_form_static_item_value}>{user.phone}</span>
                            </div>
                            <div className={styles.content_form_static_item}>
                                <span className={styles.content_form_static_item_name}>{t('birthday')}</span>
                                <span className={styles.content_form_static_item_value}>{(user.birthday !== '1970-01-01')? user.birthday:'-'}</span>
                            </div>
                        </div>
                    </form>
                    </>
                )}
            </div>
        </div>
    )
}

function getRegionById(regions, id) {
    return Object.values(regions).filter(item => parseInt(item.id) === parseInt(id));
}

export default Profile