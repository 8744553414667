import React, {useEffect} from "react";
import {useTranslation} from 'react-i18next';
import {useDataDispatch} from "../../../../state/context";
import styles from './index.module.scss';
import { setSSr } from "../../../../state/actions/BaseAction";
import constants from "../../../../state/constants";

const Product = (props) => {
    const {t} = useTranslation();
    const dispatch = useDataDispatch();

	useEffect(() => {
        setSSr({dispatch, ssr:false});
    }, [dispatch]);

    return (
        <div className={styles.items}>
            <div className={styles.items_thumbnail}>
              	 <picture>
                    <source
                        srcSet={constants.API_BASE_URL + '/uploads/products/webp/200-200' + props.product.image + '.webp'}
                        type="image/webp"/>
                    <source
                        srcSet={constants.API_BASE_URL + '/uploads/products/web/200-200' + props.product.image + '.jpg'}
                        type="image/jpeg"/>
                    <img className={styles.item_thumbnail_img} loading="lazy" alt=""
                         src={constants.API_BASE_URL + '/uploads/products/web/200-200' + props.product.image + '.jpg'} />
                </picture>
            </div>
            <div className={styles.items_content}>
                <h3 className={styles.items_content_title}>{props.product['title_ru']}</h3>
                <ol className={styles.items_content_info}>
                    <li className={styles.items_content_info_item}>{t("article")}: {props.product.article}</li>
                    <li className={styles.items_content_info_item}>{t("color")}: {props.product.color_name}</li>
                    <li className={styles.items_content_info_item}>{t("size")} : {props.product.size}</li>
                    <li className={styles.items_content_info_item}>{t("quantity")} : {props.product.count}</li>
                </ol>

                <div className={styles.items_footer}>
                    <div className={styles.items_prices}>
                        <span className={styles.items_prices_base}>
                            {props.product.price}
                            <span className={styles.items_prices_base_currency}>{t("currency")}</span>
                        </span>
                        {props.product.old_price > props.product.price ? (
                            <span className={styles.items_prices_old}>
                            {props.product.old_price}
                                <span className={styles.items_prices_old_currency}>{t("currency")}</span>
                        </span>
                        ):null}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Product
