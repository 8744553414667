import constants from "../constants";

const technologyState = {
    content: {},
    menu: {}
};

const TechnologyReducer = (state, action) => {
    const {type, payload} = action;

    switch (type) {
        case constants.GET_TECHNOLOGY: {
			return {
                ...state,
                content: payload.data.technology.content,
                menu: payload.data.technology.menu
            };
        }
        default:
            return {...state};
    }
};

export {
    technologyState,
    TechnologyReducer
};