import React from 'react';
import constants from "../../../../state/constants";
import styles from './index.module.scss';

const Images = (props) => {
    const imgs = (typeof props.items === 'undefined')? [] : props.items;

    return (
        <div className={styles.items}>
            {imgs.map((value, key) => (
                <div key={value} className={`${key === 0 ? styles.items_item_full:styles.items_item_half}`}>
                    <picture>
                        <source
                            srcSet={constants.API_BASE_URL + '/uploads/products/webp/940-940' + value + '.webp'}
                            type="image/webp"/>
                        <source
                            srcSet={constants.API_BASE_URL + '/uploads/products/web/940-940' + value + '.jpg'}
                            type="image/jpeg"/>
                        <img loading="lazy" alt=""
                             src={constants.API_BASE_URL + '/uploads/products/web/940-940' + value + ".jpg"}/>
                    </picture>
                </div>
            ))}
        </div>
    );
}

export default Images