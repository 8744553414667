import constants from "../constants";

const vacancyState = {
    seo: {},
    posts: {},
    post: {},
    related: {}
};

const VacancyReducer = (state, action) => {
    const {type, payload} = action;

    switch (type) {
        case constants.GET_POSTS: {
            return {
                ...state,
                posts: payload.data.vacancies.posts,
                seo: payload.data.vacancies.seo
            };
        }
        case constants.GET_POST: {
            return {
                ...state,
                post: payload.data.vacancies.post,
                related: payload.data.vacancies.related
            };
        }
        default:
            return {...state};
    }
};

export {
    vacancyState,
    VacancyReducer
};