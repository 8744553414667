import React from "react";
import queryString from 'query-string';
import {useTranslation} from 'react-i18next';
import {useDataState,useDataDispatch} from "../../../state/context";
import  useAsyncDataFetch  from "../../Hooks/useAsyncDataFetch";
import { getSidebar } from "../../../state/actions/ShopAction";
import {Link} from 'react-router-dom';
import Filter from './filter/index';
import RangeSlider from './rangeSlider/index'
import CloseXIcon from '../../../ui/icons/close';
import styles from './index.module.scss';


const MobileSidebar = (props) => {
    const {t} = useTranslation();
    const {shop, base} = useDataState();
    const {params} = props.match;
    const {gen, cat, subcat} = params;
    const getParams = queryString.parse(props.location.search);
    const dispatch = useDataDispatch();
    useAsyncDataFetch({ promiseFn: getSidebar, dispatch }, base.ssr, { gen, cat, subcat });

    return (
        <aside id="sidebar" className={`${styles.sidebar} ${props.sidebarToggle ? styles.sidebar_open:''}`}>
            <span onClick={() => props.setSidebarToggle(false)} className={styles.sidebar_close}>
                <CloseXIcon name="close-x" color="#000" size={14} />
            </span>

            <span className={styles.sidebar_header}>{t("filter")}</span>
            {gen === 'last' ? (
                <nav className={styles.sidebar_types}>
                    <ul className={styles.sidebar_types_items}>
                        {Object.entries(shop.genders).map(([key, val]) => (
                            <li key={key} className={`${styles.sidebar_types_items_item} ${getParams.gender === val.slug ? styles.active : ''}`}>
                                <Link className={styles.sidebar_types_items_item_link}
                                      to={"?" + queryString.stringify({...getParams, ...{gender: val.slug}})}>
                                    {val['title_' + base.currentLang]}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </nav>
            ) : (
                <nav className={styles.sidebar_types}>
                    <ul className={styles.sidebar_types_items}>
                        <li className={`${styles.sidebar_types_items_item} ${getParams.attr === 'all' || typeof getParams.attr === 'undefined' ? styles.active : ''}`}>
                            <Link className={styles.sidebar_types_items_item_link}
                                  to={"?" + queryString.stringify({...getParams, ...{attr: 'all'}})}>
                                {t("all")}
                            </Link>
                        </li>
                        <li className={`${styles.sidebar_types_items_item} ${getParams.attr === 'promo' ? styles.active : ''}`}>
                            <Link className={styles.sidebar_types_items_item_link}
                                  to={"?" + queryString.stringify({...getParams, ...{attr: 'promo'}})}>
                                {t("sale")}
                            </Link>
                        </li>
                        <li className={`${styles.sidebar_types_items_item} ${getParams.attr === 'new' ? styles.active : ''}`}>
                            <Link className={styles.sidebar_types_items_item_link}
                                  to={"?" + queryString.stringify({...getParams, ...{attr: 'new'}})}>
                                {t("new")}
                            </Link>
                        </li>
                        <li className={`${styles.sidebar_types_items_item} ${getParams.attr === 'online' ? styles.active : ''}`}>
                            <Link className={styles.sidebar_types_items_item_link}
                                  to={"?" + queryString.stringify({...getParams, ...{attr: 'online'}})}>
                                {t("online_only")}
                            </Link>
                        </li>
                    </ul>
                </nav>
            )}

            {shop.maxPrice > 0 ? (
                <RangeSlider {...props} min={shop.minPrice} max={shop.maxPrice}/>
            ) : null}

            {Object.keys(shop.discounts).length !== 0 ? (
                <Filter {...props} items={shop.discounts} title={t("sale_mount")} type="discount"/>
            ) : null}

            {Object.keys(shop.sizes).length !== 0 ? (
                <Filter {...props} items={shop.sizes} title={t("sizes")} type="size"/>
            ) : null}

            {Object.keys(shop.colors).length !== 0 ? (
                <Filter {...props} items={shop.colors} title={t("color")} type="color"/>
            ) : null}

          	<Link className={styles.sidebar_reset_link} to={"?" + queryString.stringify(null)}> {t("reset")}</Link>
        </aside>

    );
};

export default MobileSidebar;
