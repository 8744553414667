import React, {useEffect} from "react";
import {isMobile} from 'react-device-detect';
import {useTranslation} from 'react-i18next';
import StickyBox from "react-sticky-box";
import {Link} from 'react-router-dom';
import TagManager from 'react-gtm-module';
import {useDataState, useDataDispatch} from "../../state/context";
import { setSSr } from "../../state/actions/BaseAction";
import Product from './product/index';
import Certificate from './certificate/index';
import Total from './total/index';
import styles from './index.module.scss';

const Cart = () => {
    const {t} = useTranslation();
    const dispatch = useDataDispatch();
    const {cart, base} = useDataState();
    const count = Object.keys(cart.products).length + Object.keys(cart.certificates).length;

    useEffect(() => {
        setSSr({dispatch, ssr:false});
    }, [dispatch]);

	useEffect(() => {
		if (typeof window !== 'undefined' && cart.products !== null && Object.keys(cart.products).length > 0) {
            const productArray = Object.entries(cart.products).map(([key, val]) => {
				return {
                  item_id: val.product.article + val.product.color_num,
                  item_name: val.product['title_' + base.currentLang],
                  coupon: '',
                  discount: 0,
                  affiliation: 'PumaTajikistan',
                  item_brand: 'Puma',
                  item_category: val.product['gen_title_' + base.currentLang],
                  item_category2: val.product.category[0]['title_' + base.currentLang],
                  item_category3: val.product.category[1]['title_' + base.currentLang],
                  item_variant: val.product.color,
                  item_list_name: 'Cart Products',
                  item_list_id: 'cart_products',
                  price: val.product.price,
                  currency: "TJS",
                  quantity: val.count
				}
            });

          TagManager.dataLayer({
            dataLayer: {
              event: 'view_cart',
              ecommerce: {
                items: productArray
              }
            }
           });
        }
    }, [cart.products]);

    return (
        <div className={styles.page}>
            <div className={styles.page_header}>
                <div className={styles.page_header_content}>
                    <h1>{t("cart")} [{count}]</h1>
                    <Link className={styles.page_header_content_link} to={'/' + base.currentLang + '/shop/all'}>
                        <span className={styles.page_header_content_link_text}>{t("continue_shoping")}</span>
                    </Link>
                </div>
            </div>

            <div className={styles.page_body}>
                <div className={styles.page_body_left}>
                  	 { Object.keys(cart.certificates).length > 0 ? (
                        <>
                        {Object.entries(cart.certificates).map(([size, item]) => (
                            <Certificate key={size} size={size} certificat={item} />
                        ))}
                        </>
                    ) : null}
                  
                    { Object.keys(cart.products).length > 0 ? (
                        <>
                        {Object.entries(cart.products).map(([barcode, item]) => (
                            <Product key={barcode} lang={base.currentLang} barcode={barcode} {...item} />
                        ))}
                        </>
                    ) : null}
                </div>
                {isMobile || base.mobile ? (
                    <div className={styles.page_body_right}>
                        <Total title={true}/>
                        <Link className={styles.page_body_right_btn}
                              to={'/' + base.currentLang + '/checkout'}>{t("proceed_checkout")}</Link>
                    </div>
                ):(
                    <StickyBox offsetTop={100} offsetBottom={0}>
                        <div className={styles.page_body_right}>
                            <Total title={true}/>
                            <Link className={styles.page_body_right_btn}
                                  to={'/' + base.currentLang + '/checkout'}>{t("proceed_checkout")}</Link>
                        </div>
                    </StickyBox>
                )}

            </div>
        </div>
    );
};

export default Cart;
