import constants from "../constants";
import StorageService from "../storage/index";
import Cookies from "../coockies/index";
import {showBtnLoader, messages} from './FormAction';
import {setSSr, setLang} from './BaseAction';
import {encrypt, decrypt} from "../encrypt";
import axios from "axios";

axios.defaults.baseURL = constants.API_URL;

const auth = ({form, dispatch}) => {
    axios.get(constants.API_BASE_URL + '/sanctum/csrf-cookie', {withCredentials: true}).then(csrf => {
        axios.post(constants.API_URL + '/login', {data: encrypt(JSON.stringify(form))}).then(resultData => {
			const response = decrypt(resultData.data);

            if (response.success) {
                Cookies.set('token', response.data.token)
                if (response.data.card !== null) StorageService.set("OWFlMTZlZmJmNDU2NzE5MGNmZiJ9", response.data.card)
                const Lang = response.data.lang;
                setLang({Lang, dispatch})
                showBtnLoader(dispatch, 'userAuthForm', false);
                dispatch({type: constants.USER_AUTH, payload: response.data});
            } else {
                messages(dispatch, 'userAuthForm', response.success, response.data, 'left');
            }
        });
    });
};

const register = ({form, dispatch}) => {
    axios.get(constants.API_BASE_URL + '/sanctum/csrf-cookie', {withCredentials: true}).then(csrf => {
        axios.post('/register', {data: encrypt(JSON.stringify(form))}).then(resultData => {
			const response = decrypt(resultData.data);
            messages(dispatch, 'userRegForm', response.success, response.data, 'left');
        }).catch(function (errorData) {
			const error = decrypt(errorData);
            messages(dispatch, 'userRegForm', error.success, error.data, 'left');
        });
    });
};

const forgotPassword = ({form, dispatch}) => {
    axios.get(constants.API_BASE_URL + '/sanctum/csrf-cookie', {withCredentials: true}).then(csrf => {
        axios.post('/forgot-password', {data: encrypt(JSON.stringify(form))}).then(resultData => {
			const response = decrypt(resultData.data);
            messages(dispatch, 'userResetForm', response.success, response.data, 'left');
        }).catch(function (errorData) {
			const error = decrypt(errorData);
            messages(dispatch, 'userResetForm', error.success, error.data, 'left');
        });
    });
};

const getUser = ({dispatch}) => {
    const token = Cookies.get('token');
    return axios.get('/user/profile', {headers: {"Authorization": `Bearer ${token}`}}).then(resultData => {
		const result = decrypt(resultData.data);
        const payload = result.data;

        setSSr({dispatch, ssr: false});

        dispatch({
            payload,
            type: constants.USER_DATA
        });
    }).catch(function (error) {
        Cookies.remove('token');
        Cookies.remove('OWFlMTZlZmJmNDU2NzE5MGNmZiJ9');
        dispatch({
            payload: {ssr: false},
            type: constants.SET_SSR
        });

        const payload = {data: {success: false, data: error.message}};
        dispatch({payload, type: constants.USER_GLOBAL_MESSAGE});
    });
}

const getOrders = ({dispatch}) => {
    const token = Cookies.get('token');
    return axios.get('/user/orders', {headers: {"Authorization": `Bearer ${token}`}}).then(resultData => {
		const result = decrypt(resultData.data);
        const payload = result.data;

        setSSr({dispatch, ssr: false});

        dispatch({
            payload,
            type: constants.USER_ORDERS
        });
    });
}

const getProducts = (dispatch, id) => {
    const token = Cookies.get('token');
    return axios.get('/user/orders/' + id, {headers: {"Authorization": `Bearer ${token}`}}).then(resultData => {
		const result = decrypt(resultData.data);
        const payload = result.data;

        setSSr({dispatch, ssr: false});

        dispatch({
            payload,
            type: constants.USER_GOODS
        });
    });
}

const updateUser = ({dispatch, data, type}) => {
    const token = Cookies.get('token');
    axios.get(constants.API_BASE_URL + '/sanctum/csrf-cookie', {withCredentials: true}).then(csrf => {
		const jsonObject = Object.fromEntries(data);
        axios.post('/user/update', {data: encrypt(JSON.stringify(jsonObject))}, {headers: {"Authorization": `Bearer ${token}`}}).then(resultData => {
            const Lang = data.lang;
            if (typeof Lang !== 'undefined') {
                setLang({Lang, dispatch});
            }
			
			const response = decrypt(resultData.data);
			messages(dispatch, type, response.success, response.data, 'left');
        });
    });
}

const applyUserCard = ({dispatch, data}) => {
    const token = Cookies.get('token');
    axios.get(constants.API_BASE_URL + '/sanctum/csrf-cookie', {withCredentials: true}).then(csrf => {
		const jsonObject = Object.fromEntries(data);
        axios.post('/user/apply-card', {data: encrypt(JSON.stringify(jsonObject))}, {headers: {"Authorization": `Bearer ${token}`}}).then(resultData => {
			const response = decrypt(resultData.data);
			
            if (response.success) {
                dispatch({type: constants.SMS_FORM, payload: true})
            }
			
			messages(dispatch, 'applyUserCardForm', response.success, response.data, 'left');
        });
    });
}

const sendSmsCode = ({dispatch, smsCode}) => {
    const token = Cookies.get('token');
    axios.get(constants.API_BASE_URL + '/sanctum/csrf-cookie', {withCredentials: true}).then(csrf => {
        axios.post('/user/apply-code', {data: encrypt(smsCode)}, {headers: {"Authorization": `Bearer ${token}`}}).then(resultData => {
			const response = decrypt(resultData.data);
            const payload = response.data;

            setSSr({dispatch, ssr: false});
            dispatch({
                payload,
                type: constants.USER_DATA
            });
        });
    });
}

const newPassword = ({dispatch, data}) => {
	axios.get(constants.API_BASE_URL + '/sanctum/csrf-cookie', {withCredentials: true}).then(csrf => {
		const jsonObject = Object.fromEntries(data);
		
		axios.post('/reset-password', {data: encrypt(JSON.stringify(jsonObject))}).then(resultData => {
			const response = decrypt(resultData.data);
			messages(dispatch, 'passwordResetForm', response.success, response.data, 'left');
		});
	});
}

const verifyEmail = ({dispatch, id, hash, expires, signature}) => {
    axios.get('/verify-email/' + id + '/' + hash + '?expires=' + expires + '&signature=' + signature).then(resultData => {
		const response = decrypt(resultData.data)
        const payload = response.data[0];
		
        dispatch({type: constants.USER_VERIFY, payload: payload})
    });
}

const logout = dispatch => {
    const token = Cookies.get('token');
    axios.post('/logout', null, {headers: {"Authorization": `Bearer ${token}`}}).then(resultData => {
		const payload = decrypt(resultData.data)
        dispatch({
            payload,
            type: constants.USER_LOGOUT
        });
    });
}

export {
    auth,
    register,
    newPassword,
    forgotPassword,
    logout,
    getUser,
    getOrders,
    getProducts,
    updateUser,
    applyUserCard,
    sendSmsCode,
    verifyEmail
};