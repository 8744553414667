import React, {useEffect} from 'react';
import queryString from 'query-string';
import {useTranslation} from 'react-i18next';
import {useDataState} from "../../../state/context";
import constants from "../../../state/constants";
import Last from "./last/index";
import Header from "./header/index";
import Product from './product/index';
import Loader from '../../../ui/loader/index';
import MobileMenu from '../sidebar/menu/mobile/index';
import Collections from '../sidebar/collections/index';
import styles from './index.module.scss';

const MobileGrid = (props) => {
    const {t} = useTranslation();
    const {shop, base} = useDataState();
    const {params} = props.match;
    const {gen} = params;
    const getParams = queryString.parse(props.location.search);

	useEffect(()=>{
        const element = document.getElementById(props.productId);
        if (element && !props.isLoading && props.productId) {
            element.scrollIntoView(false);
            console.log(element);
        }
    }, [props.isLoading]);

    return (
        <div id="grid" className={styles.grid}>
            <div className={styles.grid_menu}>
                {(gen === 'last' || gen === 'all') || typeof getParams.gender === 'undefined' ? (<h3>{t(gen)}</h3>) : (<h3>{t(getParams.gender)}</h3>)}

                {shop.collections.length > 0 ? (
                    <Collections {...props} currentLang={base.currentLang} items={shop.collections} />
                ) : (
                    <MobileMenu shop={shop} headerMenu={base.headerMenu} currentLang={base.currentLang} params={params} getParams={getParams} {...props} />
                )}
            </div>

            {gen === 'last' ? (<Last />):null}
          	
            <Header {...props} total={props.total} shopSort={props.productSort} getParams={props.getParams}/>

            {props.isLoading? (
                <Loader />
            ):(
                <>
                {typeof props.products !== 'undefined' && props.products.length > 0 ? (
                    <>
                    {Object.entries(props.products).map(([key, val]) => (                          
                      <div key={val.id} className={styles.grid_product}>
                      	<Product product={val}  offset={props.offset}/>
                      </div>
                    ))}

                    {!props.setMoreLoading? (
                        <Loader />
                    ):null}
                    </>
                ) : (
                    <div className={styles.grid_empty}>Товары не обнаружены</div>
                )}
                </>
            )}
        </div>
    );
};

export default MobileGrid;
