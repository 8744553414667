import React, { Component } from 'react';

export default class App extends Component {
    render() {
        return (
            <main className={this.props.mobile ? 'main_mobile':''}>
                {this.props.children}
            </main>
        );
    }
}