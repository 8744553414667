import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDataState, useDataDispatch} from "../../../../state/context";
import {setSSr} from "../../../../state/actions/BaseAction";
import Step1 from './step1/index';
import Step2 from './step2/index';
import Step3 from './step3/index';
import Step4 from './step4/index';
import styles from './index.module.scss';

const Steps = () => {
    const {t} = useTranslation();
    const dispatch = useDataDispatch();
	const {user} = useDataState();
    const [form, setForm] = useState({
        type: 'fiz',
        birthday: '',
        comment: '',
        lang: 'ru',
        fio: '',
        name: '',
        secondname: '',
        phone: '',
        email: '',
        street: '',
        home: '',
        apartament: '',
        region_id: undefined,
        sector: undefined,
        store: undefined,
        regionIdforApi: false
    });
    const [step2, setStep2] = useState(false);
    const [step3, setStep3] = useState(false);
    const [step4, setStep4] = useState(false);

    useEffect(() => {
        setStep2(form.delivery && (form.store || ((form.region_id || form.sector) && form.street && form.home)));
        setStep3((form.fio && form.email && form.phone && form.delivery));
        setStep4((typeof form.payment !== 'undefined' && form.delivery));
    }, [form]);

    useEffect(() => {
		setForm(user);
        setSSr({dispatch, ssr: false});
    }, [dispatch, user.id]);

    return (
        <div className={styles.page_body_left}>
            <div className={styles.checkout_step}>
                <h3>{t("delivery_type")}</h3>
                <Step1 form={form} setForm={setForm} />
            </div>
            <div className={styles.checkout_step}>
                <h3>{t("contact_information")}</h3>
                {step2 && typeof step2 !== 'undefined' ? <Step2 form={form} setForm={setForm} /> : null}
            </div>
            <div className={styles.checkout_step}>
                <h3>{t("payment")}</h3>
                {step3 && step2 && typeof step2 ? <Step3 form={form} setForm={setForm} /> : null}
            </div>

            {step4 && step3 && step2 && typeof step2 ? <Step4 form={form} setForm={setForm} /> : null}
        </div>
    );
};

export default Steps