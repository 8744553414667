import React from "react";
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom'
import styles from './index.module.scss';

const Sidebar = (props) => {
    const {t} = useTranslation();

    return (
        <aside className={styles.sidebar}>
           
        </aside>
    )
}

export default Sidebar