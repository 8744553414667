import React from "react"
import {useTranslation} from 'react-i18next';
import styles from './index.module.scss'

const Contacts = () => {
    const {t} = useTranslation();

    return (
        <div className={styles.contacts}>
            <div className={styles.contacts_row}>
                <div className={styles.contacts_row_col}>
                    <h3>{t("contacts")}</h3>
                    <div className={styles.contacts_row_col_item}>
                        <strong>{t("online_shop")}: <br /><br />
                            Пн. - Вск. 10:00-20:00</strong>
                      	<p>
                            Телефон: <strong><a href="tel:+992407500000">+992 40 750 0000</a></strong><br />
                            email: info@pumatajikistan.tj</p>
                    </div>
                </div>
                <div className={styles.contacts_row_col}>
                    <div className={styles.contacts_row_col_item}>
                        <p><strong>{t("our_stores")}:</strong><br /><br />
                            <strong>Адрес: Таджикистан, г. ДушанбеУл. Бухоро д.13 (ориентир Дом Печати, магазин МЕХХ)</strong><br /><br />
                            Пн.-Сб. <strong>10:00-21:00</strong><br />
                          	Вск. <strong>10:00-20:00</strong>
                      </p>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Contacts