import React, { lazy, Suspense, useEffect } from "react";
import {useLocation} from 'react-router-dom';
import StickyBox from "react-sticky-box";
import {isMobile} from 'react-device-detect';
import {Helmet} from "react-helmet";
import { useDataState, useDataDispatch } from "../../state/context";
import { getTechnology } from "../../state/actions/TechnologyAction";
import  useAsyncDataFetch  from "../Hooks/useAsyncDataFetch";
import NoFound from '../pages/NoFound';
import Loader from '../../ui/loader/index';
import Sidebar from './sidebar/index';
import Seo from "../layout/seo";
import styles from './index.module.scss';

const Technology = (props) => {
    const { params } = props.match;
    const { slug } = params;
    const {technology, base} = useDataState();
    const dispatch = useDataDispatch();
	const ssr = (typeof props.location.state !== 'undefined' && props.location.state.ssr === false)? false : base.ssr;
	const { hash } = useLocation();

    const { isLoading } = useAsyncDataFetch({ promiseFn: getTechnology, dispatch }, ssr, { slug });

    useEffect(() => {
        if (typeof window !== 'undefined' && !isLoading) {
          const element = document.getElementById(hash.replace('#', ''));
		  const header = document.getElementById('header');
          if(typeof element !== 'undefined' && element !== null && typeof header !== 'undefined' && header !== null) {
			const top = element.offsetTop - header.offsetHeight;
          	window.scrollTo({ top: top, behavior: "smooth"});
          }
        }
    }, [isLoading]);

	if(!base.success) {
        return <NoFound />;
    }

    const regex = /(<([^>]+)>)/gi;
    const jsonLdDescription = (technology.content['text_' + base.currentLang] !== undefined && technology.content['text_' + base.currentLang] !== null) ? technology.content['text_' + base.currentLang].replace(regex, ""):'';

    const jsonLdPage = {
      "@context": "http://schema.org",
      "@type": "WebPage",
      "name":  technology.content['title_' + base.currentLang],
      "description": jsonLdDescription,
      "publisher": {
        "@type": "ProfilePage",
        "name": "Sport Spirit"
      }
    };

    return (
        <>
			<Helmet>
                <script type="application/ld+json">{JSON.stringify(jsonLdPage)}</script>
            </Helmet>

            <Seo data={technology.content} lang={base.currentLang} />

            <div className={styles.support}>
              {isMobile || base.mobile ? (
              <Sidebar Lang={base.currentLang} data={technology.menu} slug={slug} />
              ):(
              <StickyBox offsetTop={100} offsetBottom={0}>
                <Sidebar Lang={base.currentLang} data={technology.menu} slug={slug} />
              </StickyBox>
              )}


              <div className={styles.support_content}>
                {isLoading ? (
                	<Loader />
                ):(
                <>
                <h2>{technology.content['title_' + base.currentLang]}</h2>

                <div className={styles.support_content_text}>
                  <div dangerouslySetInnerHTML={{ __html: technology.content['text_' + base.currentLang] }} />
                </div>
                </>
                )}
              </div>
             </div>
        </>
    );
};

export default Technology;
