import React, {useEffect} from "react";
import {Trans, useTranslation} from 'react-i18next';
import { useDataState } from "../../../state/context";
import styles from './index.module.scss';

const Error = (props) => {
    const {t} = useTranslation();
	const {base} = useDataState();

    return (
        <div className={styles.container}>
          	<div className={styles.order_error}>
              <h1 className={styles.order_error_title}>{t('error')}</h1>
              <p className={styles.order_error_text}>{t('payment_error')}</p>
              <a className={styles.order_error_link} href={"/" + base.currentLang}>{t('back_to_cart')}</a>
            </div>
        </div>
    );
};

export default Error;