import React from "react";
import styles from './index.module.scss';

const FormLoader = () => {
    return (
        <div className={styles.loader} >
            <span />
            <span />
            <span />
            <span />
            <span />
        </div>
    )
}

export default FormLoader