import React from 'react'
import {useTranslation} from 'react-i18next';
import styles from './index.module.scss';

const Quantity = (props) => {
    const {t} = useTranslation();
    const quantity = (props.data.quantity <= props.data.quantityTotal) ? props.data.quantity : props.data.quantityTotal;

    const handleMinus = () => {
        if(quantity > 1) {
            props.data.setQuantity(parseInt(quantity) - 1)
        }
    }

    const handlePlus = () => {
        const total = parseInt(quantity) + 1;
        props.data.setQuantity((props.data.quantityTotal >= total) ? total : parseInt(quantity))
    }

    const handleValueChange = (e) => {
        const value = parseInt(e.target.value)

        if(e.type === 'blur' && value >= props.data.quantityTotal){
            props.data.setQuantity(props.data.quantityTotal)
        } else {
            props.data.setQuantity(value === 0 || isNaN(value) ? 1 : value)
        }        
    }

    return (
        <div className={styles.quantity}>
            <label className={styles.quantity_label}>{t('quntity')}</label>
            <div className={styles.quantity_items}>
                <button onClick={handleMinus} className={styles.quantity_items_btn}>-</button>
                <input onChange={(e) => handleValueChange(e)} onBlur={(e) => handleValueChange(e)} className={styles.quantity_items_value} type="text" value={quantity} />
                <button onClick={handlePlus} className={styles.quantity_items_btn}>+</button>
            </div>
        </div>
    );
}

export default Quantity