import React from 'react';
import LogoIcon from '../../../ui/icons/logo';
import styles from './index.module.scss';

const Logo = (props) => {
    return (
        <div className={styles.logo}>
            <a href={"/" + props.currentLang} className={styles.logo_link}>
                <LogoIcon name="logo" color="#fff" size={56} />
            </a>
        </div>
    );
}

export default Logo