import React from 'react';
import {Trans} from 'react-i18next';
import constants from "../../../../state/constants";
import styles from './index.module.scss';

const Last = (props) => {

    return (
        <div className={styles.last}>
            <div className={styles.last_banner}>
                <picture>
                    <img className={styles.last_banner_img} loading="lazy" alt="" src={constants.API_BASE_URL + "/images/last.jpg"}/>
                </picture>
            </div>
            <div className={styles.last_content}>
              <Trans i18nKey="last_size_text">
                <h4>Последний размер – последняя цена!</h4>
                <p>В этом разделе находятся товары, которые остались в единственном размере. Их можно приобрести по самой выгодной цене и <span><strong>ТОЛЬКО ОНЛАЙН!</strong></span><br />
                Список товаров обновляется еженедельно, каждый вторник.<br /><span><strong>ВНИМАНИЕ: СКИДКИ ПО ДИСКОНТНЫМ КАРТАМ НА ЭТОТ ТОВАР НЕ РАСПРОСТРАНЯЮТСЯ.</strong></span></p>
              </Trans>
            </div>
        </div>
    );
}

export default Last