import constants from "../constants";

const showBtnLoader = (dispatch, form, loading) => {
    dispatch({type: constants.FORM_BTN_LOADER, payload: {form: form, loading: loading}});
};

const messages = (dispatch, form, status, messages, align) => {
    dispatch({type: constants.FORM_MESSAGE, payload: {form: form, messages: messages, status:status, loading: false, align:align}});
};


const toBase64 = function (file , callBack) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
        callBack(file,reader.result);
    };
};

export {
    showBtnLoader,
    messages,
    toBase64
};