import React, {useState} from 'react'
import queryString from 'query-string'
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import {useDataDispatch} from "../../../../state/context";
import {search, clearSearch} from "../../../../state/actions/ShopAction";
import Product from '../../../cart/checkout/product/index'
import SearchIcon from '../../../../ui/icons/search';
import NexArrowIcon from '../../../../ui/icons/nextArrow';
import CloseXIcon from '../../../../ui/icons/close';
import styles from './index.module.scss'

const Search = (props) => {
    const dispatch = useDataDispatch();
    const getParams = typeof window !== 'undefined'? queryString.parse(window.location.search):{search: props.searchValue.search};
    const [searchvalue, setSearchvalue] = useState(typeof getParams.search !== "undefined" ? getParams.search:'');
    const [close, setClose] =useState(true);
	const lang = props.currentLang;

    const changeHandler = (e) => {
        const value = e.target.value
        setSearchvalue(value);
        setClose(false);

        if(value.length > 2){
            search({value, lang, dispatch});
        }else{
            clearSearch({dispatch});
        }
    };

    const closeHandler = () => {
        setSearchvalue('');
        setClose(true);
    }

    return (
        <div className={styles.search} itemScope="" itemType="https://schema.org/WebSite">
          	<link itemProp="url" href="https://sportspirit.md/" />
            <form className={styles.search_form} action={"/"+props.currentLang+"/shop/all"} method="get" itemProp="potentialAction" itemScope="" itemType="https://schema.org/SearchAction">
                <SearchIcon name="search" color="#fff" size={20} />
              	<meta itemProp="target" content={"https://sportspirit.md/"+props.currentLang+"/shop/all?search={search}"} />
                <meta itemProp="query-input" content="required name=search" />
                <input name="search" type="search" itemProp="query" onChange={(e) => changeHandler(e)} value={searchvalue} className={styles.search_form_input} />
                {searchvalue.length > 0 ? (
                    <span onClick={closeHandler} className={styles.search_form_close}><CloseXIcon name="close-x" color="#adadaa" size={12}  /></span>
                ):null}
            </form>
          	{(Object.keys(props.search).length > 0 && !close) ? (
          		<div className={styles.search__live}>
                  <div className={styles.search__live_half}>
                    {props.search.items.length > 0 ? (
                        <SimpleBar style={{ maxHeight: 350 }}>
                            {props.search.items.map((item) => (
                                <Product lang={lang} key={item.id} size={false} product={item} disableRemove={true} />
                            ))}
                        </SimpleBar>
                    ):(
                        <p>Товары не обнаружены</p>
                    )}
                  </div>
                  <div className={styles.search__live_half}>
                    <ul className={styles.search__live_half_items}>
                        {props.search.words.map((wordItem) => (
                            <li key={wordItem['title_'+props.currentLang]} className={styles.search__live_half_items_item}>
                              	{typeof wordItem['title_'+props.currentLang] !== "undefined" ? (
                              		<a className={styles.search__live_half_items_item_link} href={"/" + props.currentLang + "/shop/all?search=" + wordItem['title_' + props.currentLang].replace(/(<([^>]+)>)/gi, "")} dangerouslySetInnerHTML={{ __html: wordItem['title_'+props.currentLang] }} />
                              	):null}
                            </li>
                      
                        ))}
                    </ul>
                    <ul className={styles.search__live_half_items}>
                        {props.search.links.map((linkItem) => (
                            <li key={linkItem['title_'+props.currentLang]} className={styles.search__live_half_items_item}>
                               <a className={styles.search__live_half_items_item_link} href={'/'+props.currentLang+linkItem.url}>
                                 {(typeof linkItem.parent !== 'undefined')? (
                                        <>
                                        {linkItem.parent['title_'+props.currentLang]}
                                        &nbsp;<NexArrowIcon name="chevron-right" color="#818181" size={10} />&nbsp;
                                        {linkItem['title_'+props.currentLang]}
                                        </>
                                    ):(linkItem['title_'+props.currentLang])}
                                </a>
                            </li>
                        ))}
                    </ul>
                  </div>
          		</div>
          	):null}
        </div>
    );
}

export default Search