import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import Cookies from "../../../../state/coockies/index";
import {useDataState, useDataDispatch} from "../../../../state/context";
import  useAsyncDataFetch  from "../../../Hooks/useAsyncDataFetch";
import {getUser, auth, register, forgotPassword} from "../../../../state/actions/UserAction";
import {showBtnLoader} from "../../../../state/actions/FormAction";
import {validation} from "../../../../ui/form/validaion/index";
import Visibility from '../../../../ui/visibility/index';
import Input from '../../../../ui/form/input/index';
import Button from '../../../../ui/form/button/index';
import UserIcon from '../../../../ui/icons/user';
import CloseXIcon from '../../../../ui/icons/close';
import styles from './index.module.scss';

const User = (props) => {
    const {t} = useTranslation();
    const [ressetPass, setRessetPass] = useState(false);
    const [regAuth, setRegAuth] = useState(true);
    const [formErrors, setFormErrors] = useState({});
    const [form, setForm] = useState({name: '', lastname: '', surname: '', phone: '', email: '', password: '', confirm_password: '', lang: props.currentLang});
    const {user} = useDataState();
    const [toggleLink, toggleBlock, isVisible, setIsVisible] = Visibility(false);
    const dispatch = useDataDispatch();
	const {isLoading} = useAsyncDataFetch({promiseFn: (typeof Cookies.get('token') !== 'undefined' && user.token === Cookies.get('token')) ? getUser:false, dispatch}, false, {});

    const handleAuth = (e) => {
        e.preventDefault();
        setRegAuth(true)
        setRessetPass(false)
        props.user.globalSuccess = null
    };

    const handleRegistration = (e) => {
        e.preventDefault();
        setRegAuth(false)
        setRessetPass(false)
        props.user.globalSuccess = null
    };

    const handleRessetPssword = (e) => {
        e.preventDefault();
        setRessetPass(true)
        props.user.success = null
    };

    const handleClose = () => {
        setIsVisible(false)
        props.user.globalSuccess = null
    };

    const handleToggle = () => {
        if(typeof Cookies.get('token') !== 'undefined' && user.token === Cookies.get('token') && !isLoading){
            setIsVisible(false);
            window.location = "/"+props.currentLang+"/user/profile";
        }else {
            if (isVisible) {
                setIsVisible(false)
            } else {
                setIsVisible(true)
            }
        }

        props.user.globalSuccess = null
    };

    const changeHandler = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        if(name === 'confirm_password') setFormErrors({...formErrors, [name]:validation(name, [value,form.password])});
        else setFormErrors({...formErrors, [name]:validation(name, value)});

        setForm({...form, [name]: value})
        props.user.globalSuccess = null
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if(typeof Cookies.get('token') !== 'undefined' && user.token === Cookies.get('token') && !isLoading){
            setIsVisible(false);
        }else{
            setIsVisible(true);
        }

		showBtnLoader(dispatch, 'userAuthForm', true);
        auth({form, dispatch});
    }

    const handleForgotPassword = (event) => {
        event.preventDefault();
		showBtnLoader(dispatch, 'userResetForm', true);
        forgotPassword({form, dispatch});
    }

    const handleSubmitRegister = (event) => {
        event.preventDefault();
		showBtnLoader(dispatch, 'userRegForm', true);
        register({dispatch, form});
    }

    useEffect(() => {
        if(user.redirect) {
			setIsVisible(false);
		}
    });

    return (
        <>
            <div ref={toggleLink} className={styles.user} onClick={handleToggle}>
                <UserIcon name="user" color="#fff" size={40}/>
            </div>
            <div ref={toggleBlock} className={`${styles.user_slide} ${isVisible ? styles.user_slide_open : ''}`}>
                <div className={styles.user_slide__header}>
                    {ressetPass ? (
                        <h4>{t("recover_password")}</h4>
                    ) : regAuth ? (
                        <h4>{t("auth")}</h4>
                    ) : (
                        <h4>{t("registration")}</h4>
                    )}
                    <span onClick={handleClose}>
                        <CloseXIcon name="close-x" color="#000" size={14} />
                    </span>
                </div>
                <div className={styles.user_slide__body}>
                    {ressetPass ? (
                        <form onSubmit={handleForgotPassword}>
                            <Input type="email" name="email" error={formErrors.email} changeVal={changeHandler} value={form.email}
                                   label={t("email")}/>
                            <Button formName="userResetForm" type="submit" text={t("resete password")}/>
                        </form>
                    ) : regAuth ? (
                        <form onSubmit={handleSubmit}>
                            <Input type="email" name="email" error={formErrors.email} changeVal={changeHandler} value={form.email}
                                   label={t("email")}/>
                            <Input type="password" name="password" error={formErrors.password} changeVal={changeHandler} value={form.password}
                                   label={t("password")}/>
                            <Button formName="userAuthForm" type="submit" text={t("auth")}/>
                        </form>
                    ) : (
                        <form onSubmit={handleSubmitRegister}>
                            <Input changeVal={changeHandler} type="text" error={formErrors.name} name="name" value={form.name}
                                   label={t("name")}/>
                          	<Input changeVal={changeHandler} type="text" error={formErrors.lastname} name="lastname" value={form.lastname}
                                   label={t('secondname')}/>
                          	<Input changeVal={changeHandler} type="text" error={formErrors.surname} name="surname" value={form.surname}
                                   label={t("surname")}/>
                          
                            <Input changeVal={changeHandler} type="text" error={formErrors.phone} name="phone" value={form.phone}
                                   label={t("phone")}/>
                            <Input changeVal={changeHandler} type="email" error={formErrors.email} name="email" value={form.email}
                                   label={t("email")}/>
                            <Input changeVal={changeHandler} type="password" error={formErrors.password} name="password" value={form.password}
                                   label={t("password")} />
                            <Input changeVal={changeHandler} type="password" error={formErrors.confirm_password} name="confirm_password"
                                   value={form.confirm_password} label={t("repeat_password")}/>
                            <Button formName="userRegForm" type="submit" text={t("register")}/>
                        </form>

                    )}
                </div>
                <div className={styles.user_slide__footer}>
                    {regAuth && !ressetPass ? (
                        <p className={styles.user_slide__footer_text}>
                            {t("no_account")}
                            <Link onClick={handleRegistration} className={styles.user_slide__footer_text_link} to="#">
                                {t("register")}
                            </Link>
                        </p>
                    ) : (
                        <p className={styles.user_slide__footer_text}>
                            {t("have_account")}
                            <Link onClick={handleAuth} className={styles.user_slide__footer_text_link} to="#">
                                {t("enter")}
                            </Link>
                        </p>
                    )}

                    <p className={styles.user_slide__footer_text}>
                        {t("forgot_password")}
                        <Link onClick={handleRessetPssword} className={styles.user_slide__footer_text_link} to="#">
                            {t("restore")}
                        </Link>
                    </p>
                </div>
            </div>
        </>
    );
}

export default User;