import constants from "../constants";

const formState = {};

const FormReducer = (state, action) => {
    const {type, payload} = action;

    switch (type) {
        case constants.FORM_BTN_LOADER: {
            return {
                ...state,
                form: payload.form,
                loading: payload.loading,
                status: undefined
            };
        }
        case constants.FORM_MESSAGE: {
            return {
                ...state,
                form: payload.form,
                messages: payload.messages,
                status: payload.status,
                loading: payload.loading,
                align: payload.align
            };
        }
        default:
            return {...state};
    }
};

export {
    formState,
    FormReducer
};