import React from 'react'
import {useTranslation} from 'react-i18next';
import Input from '../../../../../ui/form/input/index'
import styles from './index.module.scss';

const Step = (props) => {
    const {t} = useTranslation();
    const changeHandler = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        props.setForm({...props.form, [name]: value});
    }

    return (
        <div className={styles.content}>
            <div className={styles.content_items}>
                {props.form.type === 'fiz' ? (
                    <>
                    <Input type="text" name="fio" changeVal={changeHandler} value={props.form.fio} label={t("fio")} />
                    <Input type="email" name="email" changeVal={changeHandler} value={props.form.email} label={t("email")} />
                    <Input type="text" name="phone" changeVal={changeHandler} value={props.form.phone} label={t("company_phone")} />
                    </>
                ):(
                    <>
                    <Input type="text" name="company_name" width="full" changeVal={changeHandler} value={props.form.company_name} label={t("company_name")} />
                    <Input type="text" name="bank_code" width="half" changeVal={changeHandler} value={props.form.bank_code} label={t("bank_code")} />
                    <Input type="text" name="iban" width="half" changeVal={changeHandler} value={props.form.iban} label={t("iban")} />
                    <Input type="text" name="company_address" width="full" changeVal={changeHandler} value={props.form.company_address} label={t("company_address")} />
                    <Input type="text" name="fiscal_code" width="half" changeVal={changeHandler} value={props.form.fiscal_code} label={t("fiscal_code")} />
                    <Input type="text" name="nds_code" width="half" changeVal={changeHandler} value={props.form.nds_code} label={t("nds_code")} />
                    <Input type="email" width="half" name="company_email" changeVal={changeHandler} value={props.form.company_email} label={t("email")} />
                    <Input type="text" width="half" name="company_phone" changeVal={changeHandler} value={props.form.company_phone} label={t("company_phone")} />
                    </>
                )}
            </div>
        </div>
    );
};

export default Step