import React from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import CloseXIcon from '../../../../../ui/icons/close';
import NexArrowIcon from '../../../../../ui/icons/nextArrow';
import LangButtons from '../../../header/languages/index';
import styles from './index.module.scss';

const Settings = (props) => {
    const {t} = useTranslation();

    return (
        <div className={`${styles.settings_slide} ${(props.settings)? styles.settings_slide_open:''}`}>
            <div className={styles.settings_slide_header}>
                <h4>{t("Settings")}</h4>
                <span onClick={() => props.setSettings(false)}>
                    <CloseXIcon name="close-x" color="#000" size={14}/>
                </span>
            </div>
            <div className={styles.settings_slide_body}>
                <LangButtons langs={props.langs} currentLang={props.currentLang} mobile={true}/>
                <nav className={styles.settings_slide_body_nav}>
                    <ul className={styles.settings_slide_body_nav_items}>
                        <li className={styles.settings_slide_body_nav_items_item}>
                            <Link onClick={() => props.setSettings(false)} className={styles.settings_slide_body_nav_items_item_link}
                                  to={"/" + props.currentLang + "/vacancies"}>
                                {t("vacancies")}
                                <NexArrowIcon name="chevron-right" color="#222" size={14}/>
                            </Link>
                        </li>
                    </ul>

                    <ul className={styles.settings_slide_body_nav_items}>
                        {Object.values(props.footerMenu).map((item) => (
                            <li key={item.slug} className={styles.settings_slide_body_nav_items_item}>
                                <Link onClick={() => props.setSettings(false)} className={styles.settings_slide_body_nav_items_item_link}
                                      to={"/" + props.currentLang + '/page/' + item.slug}>
                                    {item['title_' + props.currentLang]}
                                    <NexArrowIcon name="chevron-right" color="#222" size={14}/>
                                </Link>
                            </li>
                        ))}
                    </ul>
                </nav>
            </div>
        </div>
    )
}

export default Settings