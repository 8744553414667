import React from 'react';
import {useDataState} from "../../../state/context";
import FormLoader from "../../../ui/form/formloader";
import Messages from '../../../ui/form/messages/index';
import styles from './index.module.scss';

const Button = ({type, text, formName}) => {
    const {formState} = useDataState();

    return (
        <>
        <div className={styles.item}>
            {typeof formState.form !== 'undefined' && formState.form === formName && formState.loading ? (
                <FormLoader />
            ) : (
                <button className={styles.item_btn} type={type}>{text}</button>
            )}
        </div>

        {typeof formState.status !== 'undefined' && formState.form === formName ? (
            <Messages status={formState.status} data={formState.messages} align={formState.align}/>
        ) : null}
        </>
    );
};

export default Button