import React from 'react';
import queryString from 'query-string';
import {Link} from 'react-router-dom';
import styles from './index.module.scss';

const MobileMenu = (props) => {
    const {gen, cat, subcat} = props.params;
    let menuItems;
    let getRequest = (Object.keys(props.getParams).length > 0) ? '?' + queryString.stringify(props.getParams) : '';
    let gender = ((gen === 'all' || gen === 'last') && typeof props.getParams.gender !== 'undefined') ? props.getParams.gender:gen;

    if(gender === 'boys' ||gender === 'girls') {
        menuItems = props.headerMenu['children'].genderchilds[getKey(props.headerMenu['children'].genderchilds, gender)];
        menuItems = (typeof cat === 'undefined')? menuItems : menuItems.childs[getKey(menuItems.childs, cat)]
    }else{
        menuItems = (typeof cat === 'undefined')? props.headerMenu[gender]:props.headerMenu[gender].childs[getKey(props.headerMenu[gender].childs, cat)];
    }

    if (!menuItems) {
        for (let property in props.headerMenu) {
            if (props.headerMenu.hasOwnProperty(property) && props.headerMenu[property].childs.length > 0) {
                for (const subGender of props.headerMenu[property].genderchilds) {
                    if (subGender.slug === gen) {
                        menuItems = subGender
                    }
                }
            }
        }
    }

    return (
        <>
        {gender === 'all' || gender === 'last' ? (
            <nav className={styles.menu}>
                {Object.entries(props.shop.genders).map(([key, val]) => (
                    <Link key={val.slug} className={styles.menu_link}
                          to={"?" + queryString.stringify({...props.getParams, ...{gender: val.slug}})}>
                        {val['title_' + props.currentLang]}
                    </Link>
                ))}
            </nav>
        ) : (
            <nav className={styles.menu}>
                {menuItems ? Object.values(menuItems.childs).map((item) => (
                    <Link key={item.id} className={`${styles.menu_link} ${item.slug === cat + '-' + subcat ? styles.active : ''}`}
                          to={"/" + props.currentLang + "/shop/" + (menuItems.left_side ? linkUrlSplit(item.slug) : gender + '/' + linkUrlSplit(item.slug) + getRequest)}>{item['title_' + props.currentLang]}</Link>
                )) : null}
            </nav>
        )}
        </>

    );
}

function getKey(object, value) {
    return Object.keys(object).find(key => object[key].slug === value);
}

function linkUrlSplit(url) {
    let pieces = url.split("-");
    let newUrl = '';

    if (pieces.length > 2) {
        newUrl = pieces[0];
        pieces.splice(0, 1)
        newUrl = newUrl + "/" + pieces.join("-")
    } else {
        newUrl = pieces.join("/")
    }

    return newUrl
}

export default MobileMenu