import React, {useEffect} from 'react';
import queryString from 'query-string';
import {verifyEmail} from "../../../state/actions/UserAction";
import { useDataState, useDataDispatch } from "../../../state/context";
import styles from './index.module.scss';

const Verify = (props) => {
	const {params} = props.match;
	const {id, hash} = params;
	const getParams = queryString.parse(props.location.search);
	const {expires, signature} = getParams;
    const {user} = useDataState();
	const dispatch = useDataDispatch();

	useEffect(() => {
        verifyEmail({dispatch, id, hash, expires, signature});
    }, []);

    return (
        <div className={styles.verify}>
            {user.verify}
        </div>
    );
}

export default Verify