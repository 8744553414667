import React from 'react';
import {useTranslation} from 'react-i18next';
import styles from './index.module.scss';

const Messages = ({status, data, align}) => {
	const {t} = useTranslation();

    return (
        <div className={status? styles.success:styles.error} style={{'textAlign': align}}>
            {Object.entries(data).map(([key, val]) => (
                <>
                {typeof val === 'object' ? (
                    <>
                    {Object.entries(val).map(([k, v]) => (
                        <p key={k}>{t(v)}</p>
                    ))}
                    </>
                ):(
                    <p key={key}>{t(val)}</p>
                )}

                </>
            ))}
        </div>
    );
};

export default Messages