import React from "react"
import {useTranslation, Trans} from 'react-i18next';
import styles from './index.module.scss'

const About = () => {
	const {t} = useTranslation();

    return (
        <div className={styles.about}>
          <div className={styles.about_content}>
              <p><strong>PUMA</strong> - один из крупнейших мировых спортивных брендов, производящих обувь, одежду и аксессуары. С 1948 года бренд PUMA создает инновационные продукты для самых быстрых и лучших
               спортсменов в мире: от футбола до гольфа, от автоспорта до бега. Интернет-магазин PUMA TAJIKISTAN представляет обширный каталог спортивных товаров, включая линейки товаров для мужчин,
                              женщин и детей, а также известные коллаборации марки.</p>

              <p>Товары PUMA представлены более, чем в 120 странах. Штаб-квартира компании находится в Германии, в городе Херцогенаурах.
                Если вы увлечены интенсивными тренировками или для вас имидж всё - PUMA предлагает товары, необходимые на все случаи жизни,  где бы вы не находились. Дети также могут исследовать мир вместе с PUMA Kids.</p>

              <p>Миссия PUMA — быть самым быстрым спортивным брендом в мире.
              Благодаря нашему бесконечному стремлению развивать спорт мы «всегда быстрее» (forever faster). «Всегда быстрее» — это больше, чем быстро. Это больше, чем победа любой ценой. Это значит превращать сложности в возможности. Побеждать.
                Открывай новую страницу своей активной жизни и будь самым быстрым вместе с интернет-магазином PUMA TAJIKISTAN!</p>
          </div>
        </div>
    );
}

export default About