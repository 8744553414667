import React from 'react'
import Product from '../../grid/product/index'
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import styles from './index.module.scss';

const Related = (props) => {
    const settings =  {
        arrows: false,
        dots: false,
        autoplay: true,
        infinite: (props.related.length < 5)? false:true,
        autoplaySpeed: 3000,
        slidesToShow: 5,
        slidesToScroll: 1
    }

    return (
        <div className={styles.related}>
            <h4>Дополни образ</h4>
            <Slider {...settings}>
                {Object.entries(props.related).map(([indexProduct, product]) => (
                    <Product key={product.id} product={product} />
                ))}
            </Slider>
        </div>
    );
}

export default Related