import React, {useState} from "react";
import queryString from 'query-string';
import { Link } from 'react-router-dom';
import MinusIcon from '../../../../ui/icons/minus';
import PlusIcon from '../../../../ui/icons/plus';
import styles from './index.module.scss';

const Filter = (props) => {
    const getParams = queryString.parse(props.location.search);
	const [toggle, setToggle] = useState((props.type === 'discount'));
	
	const handleToggle = () => {
        if(toggle) {
            setToggle(false);
        } else {
            setToggle(true);
        }
    };

    return (
        <div className={`${styles.item} ${props.type === 'discount' ? styles.discount_item:''}`}>
            <div className={styles.item_title}>
                <h5 onClick={handleToggle}>
                  {props.title}
                  {props.type === 'color' || props.type === 'size'  || props.type === 'discount' ? (
                  	<span>
						{toggle ? (
							<MinusIcon name="plus" color="#000" size={13}/>
						):(
							<PlusIcon name="plus" color="#000" size={13}/>
						)}                  	
					</span>):null}
                </h5>
            </div>
            {toggle && <div className={styles.item_items}>
                <div className={styles[props.type]}>
                    {Object.entries(props.items).map(([key, val]) => (
                        <Link
                            key={val}
                            className={`${(getParams.color === val && props.type === 'color') || (getParams.size === val && props.type === 'size') || (getParams.discount === val && props.type === 'discount')? styles.active:''}`}
                            to={"?" + queryString.stringify({...getParams, ...{[props.type]:(getParams.color === val && props.type === 'color') || (getParams.size === val && props.type === 'size') || (getParams.discount === val && props.type === 'discount')? '' : val}})}
                            style={props.type === 'color' ? {background: val} : null} >
                            {props.type === 'color' ? null:val}
                        </Link>
                    ))}
                </div>
            </div>} 
        </div>
    )
}
export default Filter;