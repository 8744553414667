import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import constants from "../../../state/constants";
import {useDataDispatch} from "../../../state/context";
import {productChangeQuantity, productRemove} from "../../../state/actions/CartAction";
import {Link} from 'react-router-dom';
import styles from './index.module.scss';

const Product = (props) => {
    const {t} = useTranslation();
    const [quantity, setQuantity] = useState(props.count);
    const sizes = JSON.parse(props.product.data);
    const barcode = props.barcode;
	const totalQuantity = sizes.size[0]['totalQuantity'];
    const dispatch = useDataDispatch();

    const handleMinus = () => {
        if (quantity > 1) {
            const quantityMount = parseInt(quantity) - 1;
			const slug = props.product.slug;
            setQuantity(quantityMount);
            productChangeQuantity({slug, barcode, quantityMount, dispatch});
        }
    }

    const handlePlus = () => {
		const slug = props.product.slug;
        const quantityMount = parseInt(quantity) + 1;
        setQuantity((quantityMount > totalQuantity)? totalQuantity: quantityMount);
        productChangeQuantity({slug, barcode, quantityMount, dispatch});
    }

    const handleValueChange = (e) => {
		const slug = props.product.slug;
        const value = parseInt(e.target.value);
        let quantityMount = 0;
        
        if(e.type === 'blur' && value >= totalQuantity){
            quantityMount = totalQuantity;
        } else {
            quantityMount = value === 0 || isNaN(value) ? 1 : value;
        }
        
        setQuantity(quantityMount);
        productChangeQuantity({slug, barcode, quantityMount, dispatch});
    }

    const handleClick = () => {
        const removeBarcode = props.barcode;
        productRemove({removeBarcode, dispatch});
    }

    return (
        <div className={styles.items}>
            <div className={styles.items_thumbnail}>
                <picture>
                    <source
                        srcSet={constants.API_BASE_URL + '/uploads/products/webp/200-200' + props.product.image + '.webp'}
                        type="image/webp"/>
                    <source
                        srcSet={constants.API_BASE_URL + '/uploads/products/web/200-200' + props.product.image + '.jpg'}
                        type="image/jpeg"/>
                    <img loading="lazy" alt={props.product['title_' + props.lang]}
                         src={constants.API_BASE_URL + '/uploads/products/web/200-200' + props.product.image + '.jpg'}/>
                </picture>
                <span onClick={handleClick} className={styles.items_remove}>{t("remove")}</span>
            </div>
            <div className={styles.items_content}>
                <Link className={styles.items_content_title} to={'/' + props.lang + props.product.link}>{props.product['title_' + props.lang]}</Link>

                {props.product.new === 2 || props.product.cat === 'gift' ? (
                    <p className={styles.items_content_nodiscound}>На данную группу товаров скидка по дисконтной карте не распространяется</p>
                ):null}

                <ol className={styles.items_content_info}>
                    <li className={styles.items_content_info_item}>{t("color")}: {props.product.color_name}</li>
                    <li className={styles.items_content_info_item}>{t("size")} : {props.size}</li>
                    <li className={styles.items_content_info_item}>{t("article")}: {props.product.article + props.product.color_num}</li>
                </ol>

                <div className={styles.items_footer}>
                    <div className={styles.quantity}>
                        <label className={styles.quantity_label}>{t("quantity")}</label>
                        <div className={styles.quantity_items}>
                            <button onClick={handleMinus} className={styles.quantity_items_btn}>-</button>
                            <input onChange={(e) => handleValueChange(e)} onBlur={(e) => handleValueChange(e)} className={styles.quantity_items_value}
                                   type="text" value={quantity}/>
                            <button onClick={handlePlus} className={styles.quantity_items_btn}>+</button>
                        </div>
                    </div>
                    <div className={styles.items_prices}>
                        <span className={styles.items_prices_base}>
                            {props.product.price}
                            <span className={styles.items_prices_base_currency}>{t("currency")}</span>
                        </span>
                        {props.product.old_price > props.product.price ? (
                            <span className={styles.items_prices_old}>
                            {props.product.old_price}
                                <span className={styles.items_prices_old_currency}>{t("currency")}</span>
                        </span>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Product
