import React from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {useDataState} from "../../../state/context";
import Logo from '../../elements/mobile/header/logo/index';
import User from '../../elements/mobile/header/user/index';
import Menu from '../../elements/mobile/header/menu/index';
import DiscountIcon from '../../../ui/icons/discount';
import FreeDeliveryIcon from '../../../ui/icons/freeDelivery';
import CardIcon from '../../../ui/icons/card';
import styles from './index.module.scss'

const MobileHeader = () => {
	const {t} = useTranslation();
    const {base, user} = useDataState();

    return (
        <>
            <div id="header" className={styles.mobile_header}>
                <div className={styles.mobile_header_top}>
                    <Logo lang={base.currentLang} />
                  	<User user={user} currentLang={base.currentLang}/>
                </div>
                <Menu langs={base.langs} brands={base.brands} menu={base.headerMenu} currentLang={base.currentLang}/>
            </div>
            <div className={styles.mobile_header_discounts}>
                <Link to={"/" + base.currentLang + "/page/5-suplimentar-la-achitarea-online"} className={styles.mobile_header_discounts_item}>
                    <div className={styles.mobile_header_discounts_item_icon}>
                        <DiscountIcon name="discount" color="#000" size={24}/>
                    </div>
                    <h4 className={styles.mobile_header_discounts_item_title}>{t("additional_discount")}</h4>
                    <p className={styles.mobile_header_discounts_item_text}>{t("when_paying_online")}</p>
                </Link>
                <Link to={"/" + base.currentLang + "/page/delivery"} className={`${styles.mobile_header_discounts_item} ${styles.borders}`}>
                    <div className={styles.mobile_header_discounts_item_icon}>
                        <FreeDeliveryIcon name="discount" color="#000" size={24}/>
                    </div>
                    <h4 className={styles.mobile_header_discounts_item_title}>{t("free_shipping")}</h4>
                    <p className={styles.mobile_header_discounts_item_text}>{t("according_to_chisinau")}</p>
                </Link>
            </div>
        </>
    );
}

export default MobileHeader