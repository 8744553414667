import React from 'react';
import {useTranslation} from 'react-i18next';
import styles from './index.module.scss';

const About = (props) => {
	const {t} = useTranslation();

    return (
        <div id="fullDescription" className={styles.description}>
            <h4>{ t('about product') }</h4>
            <div className={styles.description_content} dangerouslySetInnerHTML={{ __html: props.content }} />
        </div>
    );
}

export default About