import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDataDispatch} from "../../../../state/context";
import {logout} from "../../../../state/actions/UserAction";
import {Link} from 'react-router-dom'
import styles from './index.module.scss';

const Sidebar = (props) => {
    const {t} = useTranslation();
    const dispatch = useDataDispatch();

    const userLogout = (e) => {
        e.preventDefault();
        logout(dispatch);
    };

    return (
        <nav className={styles.menu}>
            <ul className={styles.menu_items}>
                <li className={styles.menu_items_item}>
                    <Link className={styles.menu_items_item_link} to={"/" + props.Lang + "/user/profile"}>

                        {t("user_profile")}
                    </Link>
                </li>
                <li className={styles.menu_items_item}>
                    <Link className={styles.menu_items_item_link} to={"/" + props.Lang + "/user/orders"}>

                        {t("user_order_history")}
                    </Link>
                </li>
                <li className={styles.menu_items_item}>
                    <Link className={styles.menu_items_item_link} to={"/" + props.Lang + "/user/settings"}>

                        {t("user_settings")}
                    </Link>
                </li>
                <li className={styles.menu_items_item}>
                    <span className={styles.menu_items_item_link} onClick={userLogout}>

                        {t("logout")}
                    </span>
                </li>
            </ul>
        </nav>
    )
}

export default Sidebar