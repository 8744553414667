import React, {useState, useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import StickyBox from "react-sticky-box";
import {Helmet} from "react-helmet";
import TagManager from 'react-gtm-module';
import {useDataState, useDataDispatch} from "../../../state/context";
import  useAsyncDataFetch  from "../../Hooks/useAsyncDataFetch";
import {addToCart} from "../../../state/actions/CartAction";
import {getPage} from "../../../state/actions/PageAction";
import {getProduct} from "../../../state/actions/ShopAction";
import constants from "../../../state/constants";
import Loader from '../../../ui/loader/index';
import Breadcrambs from './breadcrumbs/index';
import Images from './images/index';
import About from './about/index';
import Related from './related/index';
import Page from './page/index';
import NoFound from '../../pages/NoFound';
import ProductCard from './card';
import ProductDefault from './product';
import styles from './index.module.scss';

const ProductDetail = (props) => {
    const {t} = useTranslation();
    const {params} = props.match;
    const {shop, base, page} = useDataState();
    const [showPage, setShowPage] = useState(false)
    const [productSize, setProductSize] = useState(null)
    const [quantity, setQuantity] = useState(1)
    const {slug} = params;	
    const sizes = shop.product.data ? JSON.parse(shop.product.data) : {size: []};
    const dispatch = useDataDispatch();
	const prevProductRef = useRef(null);
    const [form, setForm] = useState({
        title: '',
        price: 0,
        select_price: "",
        custom_price: "",
        recipient_name: "",
        your_name: "",
        recipient_email: "",
        your_email: "",
        comment: "",
    });

    const closePage = (e) => {
        setShowPage(e)
    }

    const getPageData = (slug, dispatch) => {
        getPage({slug, dispatch});
        setShowPage(true);
    }

    const addToCartProduct = (slug) => {
		TagManager.dataLayer({
            dataLayer: {
                event: 'add_to_cart',
                ecommerce: {
                    items: [{
                        item_id: shop.product.article + shop.product.color_num,
                        item_name: shop.product['title_' + base.currentLang],
                        coupon: '',
                        discount: 0,
                        affiliation: 'PumaTajikistan',
                        item_brand: 'PUMA',
                        item_category: shop.product['gen_title_' + base.currentLang],
                        item_category2: shop.product.category[0]['title_' + base.currentLang],
                        item_category3: (typeof shop.product.category[1] !== 'undefined') ? shop.product.category[1]['title_' + base.currentLang]:'',
                        item_variant: shop.product.color,
                        price: shop.product.price,
                        currency: "TJS",
                        quantity: 1
                    }]
                }
            }
        });
        addToCart({slug, productSize, quantity, dispatch});
    }

    const changeHandler = (e) => {
        const name = e.target.name
        const value = e.target.value

        setForm({...form, [name]: value, title: shop.product['title_' + base.currentLang]})

        if (name === 'custom_price' && value !== "") {
            setProductSize(null)
            setForm({...form, [name]: value, select_price: "", price: value})
        }
    }

    const {isLoading} = useAsyncDataFetch({promiseFn: getProduct, dispatch}, base.ssr, {slug});

	useEffect(() => {
      	if (typeof window !== 'undefined' && Object.keys(shop.product).length !== 0) {
			if ((prevProductRef.current !== shop.product && base.ssr) || (prevProductRef.current !== shop.product && prevProductRef.current !== null && !base.ssr)) {
              TagManager.dataLayer({
                 dataLayer: {
                      event: 'view_item',
                      ecommerce: {
                        items: [{
                            item_id: shop.product.article + shop.product.color_num,
                            item_name: shop.product['title_' + base.currentLang],
                            coupon: '',
                            discount: 0,
                            affiliation: 'PumaTajikistan',
                            item_brand: 'PUMA',
                            item_category: shop.product['gen_title_' + base.currentLang],
                            item_category2: shop.product.category[0]['title_' + base.currentLang],
                            item_category3: shop.product.category[1]['title_' + base.currentLang],
                            item_variant: shop.product.color,
                            price: shop.product.price,
                            currency: "TJS",
                            quantity: 1
                        }]
                    }
                  }
              });
            }
			
			prevProductRef.current = shop.product;
		}
    }, [shop.product]);

	if (isLoading || Object.keys(shop.product).length === 0) return <Loader />;

    if (!base.success && Object.keys(shop.product).length === 0) {
        return <NoFound />;
    }

    return (
        <>
        <div className={styles.wrapper}>
            <Breadcrambs lang={base.currentLang} data={shop.breadcrumbs}
                         title={shop.product['title_' + base.currentLang]}/>
            <div className={styles.row}>
                <div className={styles.row_col_left}>
                    <Images items={shop.product.image}/>
                </div>
                <div className={styles.row_col_right}>
                    <StickyBox offsetTop={180} offsetBottom={0}>
                        <div className={styles.content}>
                            <h1>{shop.product['title_' + base.currentLang]}</h1>
                            <ProductDefault product={shop.product} params={params}
                                                data={{productSize, setProductSize}} qt={{quantity, setQuantity}}
                                                sizes={sizes} Lang={base.currentLang} addProduct={addToCartProduct}
                                                stores={base.stores}/>
                        </div>
                    </StickyBox>
                </div>
            </div>
        </div>

        {shop.product['text_' + base.currentLang] ? (
            <About content={shop.product['text_' + base.currentLang]}/>
        ) : null}
        {shop.related.length > 0 ? (
            <Related related={shop.related}/>
        ) : null}
        </>
    );
}

export default ProductDetail