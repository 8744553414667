import React, {useEffect} from "react";
import {Trans, useTranslation} from 'react-i18next';
import TagManager from 'react-gtm-module';
import StorageService from "../../../state/storage/index";
import {recountCart, hiddeMiniCart, showMiniCart} from "../../../state/actions/CartAction";
import {useDataState, useDataDispatch} from "../../../state/context";
import Product from './product/index';
import styles from './index.module.scss';

const Success = (props) => {
    const {t} = useTranslation();
    const {params} = props.match;
    const {hash} = params;
    const {cart, base} = useDataState();
    const orderId = cart.order.id;
	const dispatch = useDataDispatch();

	useEffect(() => {
		StorageService.remove('products');
		recountCart(dispatch);
    }, []);

	useEffect(() => {
		if (typeof window !== 'undefined' && cart.order.goods !== null && Object.keys(cart.order.goods).length > 0) {
            const productArray = Object.entries(cart.order.goods).map(([key, val]) => {
				return {
                  item_id: val.product.article + val.product.color_num,
                  item_name: val.product['title_' + base.currentLang],
                  coupon: '',
                  discount: 0,
                  affiliation: 'PumaTajikistan',
                  item_brand: 'PUMA',
                  item_category: val.product['gen_title_' + base.currentLang],
                  item_category2: val.product.category[0]['title_' + base.currentLang],
                  item_category3: val.product.category[1]['title_' + base.currentLang],
                  item_variant: val.product.color,
                  item_list_name: 'Cart Products',
                  item_list_id: 'cart_products',
                  price: val.product.price,
                  currency: "TJS",
                  quantity: val.count
				}
            });


          TagManager.dataLayer({
            dataLayer: {
              event: 'purchase',
              ecommerce: {
				transaction_id: orderId,
                affiliation: "PumaMoldova",
                value: cart.order.total,
                tax: 0,
                shipping: cart.order.delivery_price,
                currency: t("currency"),
                coupon: "",
                items: productArray
              }
            }
           });
        }
    }, [cart.order.goods]);

    return (
        <div className={styles.container}>
            <div className={styles.container_header}>
                <h3>Order id: {orderId}</h3>
            </div>
            <div className={styles.container_row}>
                <div className={styles.container_row_left_col}>
                        <h1>Заказ принят</h1>
                        <b><span>Внимание! Для получения информации сохраните идентификатор заказа </span>
                            ( {orderId} )</b><br /><br />
                        <p>Спасибо за заказ! Ваш заказ успешно оформлен. Наш менеджер свяжется с Вами в ближайшее время.<br />Если
                            у Вас есть вопросы по Вашему заказу, то Вы можете связаться с нашим консультантом по номеру
                            : +992 40 750 0000.</p>

                    { Object.keys(cart.order.goods).length > 0 ? (
                        <>
                        {Object.entries(cart.order.goods).map(([key, item]) => (
                            <Product key={key} product={item} />
                        ))}
                        </>
                    ) : null}
                </div>
                <div className={styles.container_row_right_col}>
                    <div className={styles.container_row_right_col_label}>
                        {t("fio")}
                    </div>
                    <div className={styles.container_row_right_col_content}>
                        {cart.order.user.fio}
                    </div>
                    <div className={styles.container_row_right_col_label}>
                        {t("payment_type")}
                    </div>
                    <div className={styles.container_row_right_col_content}>
                        {t('payment_' + cart.order.pay_method)}
                    </div>
                    <div className={styles.container_row_right_col_label}>
                        {t("address")}
                    </div>
                    <div className={styles.container_row_right_col_content}>
                        {cart.order.user.address}
                    </div>
                    <div className={styles.container_row_right_col_label}>
                        {t("phone")}
                    </div>
                    <div className={styles.container_row_right_col_content}>
                        {cart.order.user.phone}
                    </div>
                    <div className={styles.container_row_right_col_label}>
                        {t("email")}
                    </div>
                    <div className={styles.container_row_right_col_content}>
                        {cart.order.user.email}
                    </div>
                    <div className={styles.container_row_right_col_label}>
                        {t("comment")}
                    </div>
                    <div className={styles.container_row_right_col_content}>
                        {cart.order.user.comment}
                    </div>
                    <div className={styles.container_row_right_col_label}>
                        {t("delivery")}
                    </div>
                    <div className={styles.container_row_right_col_content}>
                        {cart.order.delivery_price} {t("currency")}
                    </div>
                    <div className={styles.container_row_right_col_label}>
                        {t("user_order_total")}
                    </div>
                    <div className={styles.container_row_right_col_content}>
                        {cart.order.total} {t("currency")}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Success;