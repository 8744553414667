import React from 'react';
import Hamburger from 'hamburger-react';
import styles from './index.module.scss';

const BirgerMenu = (props) => {
    return (
        <div className={styles.menu}>
            <Hamburger toggled={props.mobileToggle} toggle={props.setMobileToggle}  size={24} color="#FFF" />
        </div>
    )
}

export default BirgerMenu