import React from "react";
import {useTranslation} from 'react-i18next';
import About from '../elements/footer/about/index';
import Menu from '../elements/footer/menu/index';
import Contacts from '../elements/footer/contacts/index';
import Social from '../elements/footer/social/index';
import styles from './index.module.scss'

const Footer = () => {
	const {t} = useTranslation();

    return (
        <footer className={styles.footer}>
          	<About />
            <div className={styles.footer_row}>
                <Contacts />
            </div>
          	<div className={styles.footer_copyright}>
              All rights reserved © pumatajikistan 2023 - Official distributor for PUMA in Tajikistan
          	</div>
        </footer>
    );
}

export default Footer