import React, {useCallback, useEffect, useState, useRef} from "react";
import queryString from 'query-string'
import {useTranslation} from 'react-i18next';
import styles from './index.module.scss';

const RangeSlider = (props) => {
    const {t} = useTranslation();
    const getParams = queryString.parse(props.location.search)
    const [minVal, setMinVal] = useState(parseInt(getParams.min_price ? getParams.min_price : props.min));
    const [maxVal, setMaxVal] = useState(parseInt(getParams.max_price ? getParams.max_price : props.max));
    const minValRef = useRef(null);
    const maxValRef = useRef(null);
    const range = useRef(null);

    const getPercent = useCallback(
        (val) => {
            return Math.round(((val - props.min) / (props.max - props.min)) * 100)
        },
        [props.min, props.max]
    )

    const handleEvent = (event) => {
        if (event.key === "Enter" || event.type === "mouseup" || event.type === "blur") {
            props.history.push({
                search: "?" + queryString.stringify({...getParams, ...{min_price:minVal, max_price:maxVal}})
            })
        }
    }

    const setMinValEvent = (event) => {
		setMinVal(event.target.value);
    }

	const setMaxValEvent = (event) => {
		setMaxVal(event.target.value);
    }

    useEffect(() => {
        if(!getParams.min_price){
            setMinVal(props.min)
        }

        if(!getParams.max_price){
            setMaxVal(props.max)
        }
    },[getParams.min_price, getParams.max_price, props.min, props.max])

    useEffect(() => {
        if (maxValRef.current) {
            const minPercent = getPercent(minVal);
            const maxPercent = getPercent(+maxValRef.current.value); // Precede with '+' to convert the value from type string to type number

            if (range.current) {
                range.current.style.left = `${minPercent}%`;
                range.current.style.width = `${maxPercent - minPercent}%`;
            }
        }
    }, [minVal,getPercent]);

    useEffect(() => {
        if (minValRef.current) {
            const minPercent = getPercent(+minValRef.current.value);
            const maxPercent = getPercent(maxVal);

            if (range.current) {
                range.current.style.width = `${maxPercent - minPercent}%`;
            }
        }
    }, [maxVal,getPercent]);

    return (
        <div className={styles.filter}>
            <div className={styles.filter_title}>
                <h5>{t("price_filter")}</h5>
            </div>
            <div className={styles.sidebar_filter_items}>
                <div className={styles.slider}>
                    <input
                        type="range"
                        min={props.min}
                        max={props.max}
                        value={minVal}
                        className={styles.slider_val}
                        ref={minValRef}
                        onMouseUp={handleEvent}
                        onChange={(event) => {
                            const value = Math.min(+event.target.value, maxVal - 1);
                            setMinVal(value);
                            event.target.value = value.toString();
                        }}
                    />
                    <input
                        type="range"
                        min={props.min}
                        max={props.max}
                        value={maxVal}
                        className={styles.slider_val}
                        ref={maxValRef}
                        onMouseUp={handleEvent}
                        onChange={(event) => {
                            const value = Math.max(+event.target.value, minVal + 1);
                            setMaxVal(value);
                            event.target.value = value.toString();
                        }}
                    />

                    <div className={styles.slider_item}>
                        <div className={styles.slider_item_track} />
                        <div ref={range} className={styles.slider_item_range} />
                        <div className={styles.slider_item_labels}>
                            <div className={styles.slider_item_labels_item}>
                                <span className={styles.slider_item_labels_item_text}>{t("from")}</span>
                                <input ref={minValRef}
                        onChange={setMinValEvent} tabIndex={0}
      onKeyDown={handleEvent} className={styles.slider_item_labels_item_num} type="text" value={minVal} />
                            </div>
                            <div className={styles.slider_item_labels_item}>
                                <span className={styles.slider_item_labels_item_text}>{t("to")}</span>
                                <input ref={maxValRef}
                        onChange={setMaxValEvent} tabIndex={1}
      onKeyDown={handleEvent} className={styles.slider_item_labels_item_num} type="text" value={maxVal} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default RangeSlider;