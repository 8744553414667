import React from "react";
import styles from './index.module.scss';

const Label = ({text, color, top, size, position}) => {
    const style = {
        background: color,
        top: top,
        fontSize: size
    };

    return (
        <div className={styles.item} style={style} dangerouslySetInnerHTML={{ __html: text }} />
    );
}

export default Label