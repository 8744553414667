import { useState } from "react";
import { setSSr } from "../../state/actions/BaseAction";
import { useEffectSSR } from './useEffectSSR';
import PropTypes from 'prop-types';

/**
 * SSR Custom hook which returns isLoading false in case of server-side rendering
 * otherwise it load data fro mserver and set isLoading to false
 * @param {*} param0
 * @param {*} ssr
 */
const useAsyncDataFetch =  ({ promiseFn, dispatch }, ssr = false, params = {}  ) => {
    const [isLoading, setIsLoading] = useState(false);

    useEffectSSR(() => {
        if (ssr){
            setIsLoading(false);
            setSSr({dispatch, ssr:false});
        } else {
            setIsLoading(true);
			if(promiseFn === false) {
            	setIsLoading(false);
            }else{
            promiseFn({ dispatch, ...params }).then(()=> {}).finally(()=>{
                setIsLoading(false);
            });
}
        }
    }, [params.id, params.slug, params.gen, params.cat, params.subcat, params.sort, params.color, params.discount, params.size, params.min_price, params.max_price, params.gender, params.search, params.attr], promiseFn);

    return  { isLoading };
}

useAsyncDataFetch.propTypes = {
    ssr: PropTypes.bool,
    params: PropTypes.object
}

useAsyncDataFetch.defaultProps = {
    ssr: false,
    params: {}
}

export default useAsyncDataFetch;