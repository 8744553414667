import React, {useState} from 'react';
import { useDataState } from "../../../state/context";
import { Link } from 'react-router-dom';
import styles from './index.module.scss';

const Checkbox = ({changeVal, name, value, label, popup}) => {
	const {base} = useDataState();
    const [isChecked, setIsChecked] = useState(true);

    const changeHandler = (e) => {
        setIsChecked((isChecked) ? false : true);
        e.target.value = isChecked ? 1:null;
        changeVal(e);
    }

    return (
        <div className={styles.item}>
            <input onChange={(e) => changeHandler(e)} type="checkbox" id={`${name}_${value}`} name={name} required="required" />
            <label htmlFor={`${name}_${value}`}>{label}
                {popup ? (
                    <Link to={'/'+base.currentLang + popup.link} target="_blank"> {popup.text}</Link>
                ):null}
            </label>
        </div>
    );
};

export default Checkbox