import { useState, useEffect, useRef } from 'react';

export default function Visibility(initialIsVisible) {
    const [isVisible, setIsVisible] = useState(initialIsVisible);
    const toggleLink = useRef(null);
    const toggleBlock = useRef(null);

    const handleClickOutside = (event) => {
        if (toggleBlock.current && !toggleBlock.current.contains(event.target) && toggleLink.current && !toggleLink.current.contains(event.target)) {
            setIsVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    return [toggleLink, toggleBlock, isVisible, setIsVisible];
}