import React from 'react';
import styles from './index.module.scss';

const Textarea = ({name, label, changeVal, value, width}) => {
    const changeHandler = (e) => {
        changeVal(e)
    }

    return (
        <div className={`${styles.item} ${width === 'half' ? styles.half:width === 'full' ? styles.full:''}`}>
            <label className={styles.item_label}>{label}</label>
            <textarea onChange={(e) => changeHandler(e)} className={styles.item_value} name={name} value={value} />
        </div>
    );
};

export default Textarea