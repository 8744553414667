import React from 'react'
import { Link } from 'react-router-dom';
import constants from "../../../../state/constants";
import styles from './index.module.scss';

const Color = (props) => {
	const colors = (typeof props.colors === 'string')? JSON.parse(props.colors):props.colors;

    return (
        <div className={styles.items}>
            {Object.entries(colors).map(([num, colore]) => (
                <Link key={num} className={`${styles.items_item} ${props.params.slug === props.article + '-' + num? styles.active:'' }`} to={props.article + '-' + num}>
                    <picture>
                        <source
                            srcSet={constants.API_BASE_URL + '/uploads/products/webp/200-200' + props.article + num + '.webp'}
                            type="image/webp"/>
                        <source
                            srcSet={constants.API_BASE_URL + '/uploads/products/web/200-200' + props.article + num + '.jpg'}
                            type="image/jpeg"/>
                        <img className={styles.items_item_img} loading="lazy" alt={colore[1]}
                             src={constants.API_BASE_URL + '/uploads/products/web/200-200' + props.article + num + ".jpg"}/>
                    </picture>
                    <span className={styles.items_item_name}>{colore[1]}</span>
                </Link>
            ))}
        </div>
    );
}

export default Color