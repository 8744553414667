import React from 'react';
import styles from './index.module.scss';

const Logo = (props) => {
    return (
        <a href={"/" + props.lang} className={styles.logo}>
          <picture>
            <source srcSet="/puma-logo-app.webp" type="image/webp"/>
            <source srcSet="/puma-logo-app.png" type="image/jpeg"/>
            <img className={styles.logo_img} loading="lazy" width="163" height="35" alt="SportSpirit" src="/puma-logo-app.png"/>
          </picture>
        </a>
    );
}

export default Logo