import React from 'react';
import {useTranslation} from 'react-i18next';
import { Link } from 'react-router-dom';
import constants from "../../../../state/constants";
import {useDataDispatch} from "../../../../state/context";
import {certificateRemove} from "../../../../state/actions/CartAction";
import styles from './index.module.scss';

const Certificate = (props) => {
    const {t} = useTranslation();
    const dispatch = useDataDispatch();

    const handleClick = () => {
        const removeBarcode = props.certificat.price+props.certificat.recipient_name;
        certificateRemove({removeBarcode, dispatch});
    }

console.log('Certificate', props);

    return (
        <div className={styles.items}>
            <div className={styles.items_thumbnail}>
                <img src={constants.API_BASE_URL + '/images/products/jpg/pa0001462x462.jpg?v6.76'} alt="" />
                <span onClick={handleClick} className={styles.items_remove}>{t("remove")}</span>
            </div>
            <div className={styles.items_content}>
                <Link className={styles.items_content_title} to="/">{props.certificat.title}</Link>

                <div className={styles.items_footer}>
                    <div className={styles.quantity}>

                    </div>
                    <div className={styles.items_prices}>
                        <span className={styles.items_prices_base}>
                            {props.certificat.price}
                            <span className={styles.items_prices_base_currency}>{t("currency")}</span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Certificate