import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDataDispatch} from "../../../state/context";
import {addToCartCertificat} from "../../../state/actions/CartAction";
import Excerpt from "../../../HOC/excerpt";
import PriceSelect from './priceSelect/index';
import Input from '../../../ui/form/input/index';
import Textarea from '../../../ui/form/textarea/index';
import styles from './index.module.scss';

const ProductCard = (props) => {
    const {t} = useTranslation();
    const dispatch = useDataDispatch();
    const minVal = 200;
    const maxVal = 100000;
    const [form, setForm] = useState({
        title: '',
        price: 0,
        select_price: "",
        custom_price: "",
        recipient_name: "",
        your_name: "",
        comment: "",
    });

    const scrollHandler = (id) => {
        const element = document.getElementById(id);
        const top = element.offsetTop - document.getElementById('header').offsetHeight;

        if(typeof element !== 'undefined') window.scrollTo({top: top, behavior: "smooth"});
    }

    const changeHandler = (e) => {
        const name = e.target.name
        const value = e.target.value

        setForm({...form, [name]: value, title: props.product['title_' + props.Lang]})


        if (name === 'custom_price' && value !== "") {
            let priceVal = value;
            if(e.type === 'blur' && value <= minVal) {
                priceVal = minVal;
            }else if(e.type === 'blur' && value >= maxVal){
                priceVal = maxVal;
            }

            setForm({...form, [name]: priceVal, select_price: "", price: priceVal});
        }
    }

    const addToCartProduct = () => {
        addToCartCertificat(form, props.product.price, dispatch);
    }

    return (
        <>
        <div className={styles.row}>
            <PriceSelect data={{form: form, setForm: setForm}} items={props.product.palette}
                         price={props.product.price}/>
            <Input type="number" name="custom_price" width="half" changeVal={changeHandler}
                   value={form.custom_price} label={t('custom_price')}/>
            <Input type="text" name="recipient_name" width="half" changeVal={changeHandler}
                   value={form.recipient_name} label={t('recipient_name')}/>
            <Input type="text" name="your_name" width="half" changeVal={changeHandler}
                   value={form.your_name} label={t('your_name')}/>
            <Textarea name="comment" changeVal={changeHandler} label={t('comment')}
                      value={form.comment} width="full"/>
            <button className={styles.add_to_cart_cert}
                    onClick={() => addToCartProduct()}>
                {t('add_to_cart')}
            </button>
        </div>
        <div className={styles.content_excerpt}>
            <p dangerouslySetInnerHTML={{__html: Excerpt(props.product['text_' + props.Lang], 40, '...')}}/>
            <span className={styles.more} onClick={() => scrollHandler('fullDescription')}>читать далее</span>
        </div>
        </>
    )
}

export default ProductCard