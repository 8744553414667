import React, {useState} from 'react';
import queryString from 'query-string';
import {Link} from 'react-router-dom';
import MinusIcon from '../../../../ui/icons/minus';
import PlusIcon from '../../../../ui/icons/plus'
import styles from './index.module.scss';

const Menu = (props) => {
    const {gen, cat} = props.params;
    let getRequest = (Object.keys(props.getParams).length > 0) ? '?' + queryString.stringify(props.getParams) : ''
    let menuItems = props.headerMenu[gen];

    if (!menuItems) {
        for (var property in props.headerMenu) {
            if (props.headerMenu.hasOwnProperty(property) && props.headerMenu[property].childs.length > 0) {
                for (const subGender of props.headerMenu[property].genderchilds) {
                    if (subGender.slug === gen) {
                        menuItems = subGender
                    }
                }
            }
        }
    }

    return (
        <nav className={styles.menu}>
            <ul className={styles.menu_items}>
                {menuItems ? Object.values(menuItems.childs).map((item) => (
                    <li key={'sidebar' + item.id} className={styles.menu_items_item}>
                        <Link className={`${styles.menu_items_item_link} ${item.slug === cat ? styles.active : ''}`}
                              to={"/" + props.currentLang + "/shop/" + (menuItems.left_side ? item.slug : gen + '/' + item.slug + getRequest)}>{item['title_' + props.currentLang]}</Link>
                        {item.childs ? (
                            <ToggleItem item={item} items={item.childs} getRequest={getRequest} params={props.params} lang={props.currentLang} />
                        ) : item.genderchilds[gen] ? (
							 <ToggleItem item={item} items={item.genderchilds[gen]} getRequest={getRequest} params={props.params} lang={props.currentLang} />
                        ) : null}
                    </li>
                )) : null}
            </ul>
        </nav>
    );
}

const ToggleItem = ({item, items, getRequest, params, lang}) => {
	const {gen, cat, subcat} = params;
	const [toggle, setToggle] =  useState(false);
	
	const menuTuggle = () => {
        if(toggle) setToggle(false);
		else setToggle(true);
    };
	
	return (
		<>
			<span className={styles.menu_items_item_icon} onClick={menuTuggle}>
				{item.slug === cat || toggle ? (
					<MinusIcon name="minus" color="#000" size={13}/>
				) : (
					<PlusIcon name="plus" color="#000" size={13}/>
				)}
			</span>

			<ul className={`${styles.sub_menu} ${item.slug === cat || toggle ? styles.sub_menu_open : null}`}>
				{Object.values(items).map((subItem) => (
					<li className={styles.sub_menu_item} key={'sidebar' + subItem.id}>
						<Link
							className={`${styles.sub_menu_item_link} ${subItem.slug === cat + "-" + subcat ? styles.sub_active : ''}`}
							to={"/" + lang + "/shop/" + gen + '/' + linkUrlSplit(subItem.slug) + getRequest}>{subItem['title_' + lang]}</Link>
					</li>
				))}
			</ul>
		</>
	);
}

function linkUrlSplit(url) {
    let pieces = url.split("-");
    let newUrl = '';

    if (pieces.length > 2) {
        newUrl = pieces[0];
        pieces.splice(0, 1)
        newUrl = newUrl + "/" + pieces.join("-")
    } else {
        newUrl = pieces.join("/")
    }

    return newUrl
}

export default Menu