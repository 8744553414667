import React from 'react';
import { Link } from 'react-router-dom';
import {useDataDispatch} from "../../../state/context";
import {certificateRemove} from '../../../state/actions/CartAction';
import styles from './index.module.scss';

const Certificate = (props) => {
    const dispatch = useDataDispatch();

    const handleClick = () => {
        const removeBarcode = props.certificat.price+props.certificat.recipient_name;
        certificateRemove({removeBarcode, dispatch});
    }

    return (
        <div className={styles.items}>
            <div className={styles.items_thumbnail}>
                <img src="https://pumamoldova.md/images/products/jpg/pa0001462x462.jpg?v6.76" alt="Puma Black" />
                <span onClick={handleClick} className={styles.items_remove}>Удалить</span>
            </div>
            <div className={styles.items_content}>
                <Link className={styles.items_content_title} to={'/' + props.lang + '/shop/unisex/gift/card/pa00-01'}>{props.certificat.title}</Link>
                <p className={styles.items_content_nodiscound}>На данную группу товаров скидка по дисконтной карте не распространяется</p>
                <ol className={styles.items_content_info}>
                    <li className={styles.items_content_info_item}>Recipient name: {props.certificat.recipient_name}</li>
                    <li className={styles.items_content_info_item}>Your name : {props.certificat.your_name}</li>
                    <li className={styles.items_content_info_item}>Комментарий: {props.certificat.comment}</li>
                </ol>

                <div className={styles.items_footer}>
                    <div className={styles.quantity}>

                    </div>
                    <div className={styles.items_prices}>
                        <span className={styles.items_prices_base}>
                            {props.certificat.price}
                            <span className={styles.items_prices_base_currency}>тг</span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Certificate