import React from "react";
import NexArrowIcon from '../../icons/nextArrow'

const NextArrow = (props) => {
    const {className, style, onClick} = props

    return (
        <div className={className} onClick={onClick} style={{...style}}>
            <NexArrowIcon name="chevron-right" color="#999" size={40} />
        </div>
    )
}

export default NextArrow