import React from "react";
import styles from './index.module.scss';

const File = ({label, changeVal, name}) => {

	const changeHandler = (e) => {
        changeVal(e)
    }

    return (
        <div className={styles.items}>
            <span className={styles.items_label}>{label}</span>
            <input className={styles.items_file} type="file" onChange={(e) => changeHandler(e)} name={name} />
  		</div>
    )
}

export default File