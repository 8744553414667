import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDataState} from "../../../state/context";
import InputMask from 'react-input-mask';
import styles from './index.module.scss';

const Input = ({width, type, changeVal, name, value, label, error, required = true}) => {
	const {t} = useTranslation();
 	const {base} = useDataState();
	const [errorType, setErrorType] = useState(false);
	const regexp = /^[a-z@.\-_+ \d]+$/i;

    const changeHandler = (e) => {

		if(name == 'name' || name == 'lastname' || name == 'surname') {
          const value = e.target.value;

          if (regexp.test(value)) {
          	setErrorType(false);
          } else {
          	setErrorType("Only Latin characters, numbers, and special characters are allowed @.-_");
          }
        }

		changeVal(e);
    }

    return (
        <div className={`${styles.item} ${width === 'half' ? styles.half:width === 'full' ? styles.full:''}`}>
            <label className={styles.item_label}>{label}</label>
            {name === 'phone' || name === 'company_phone'? (
                <InputMask onChange={(e) => changeHandler(e)} mask="+\9\92\ 999-999-999" maskChar=" " value={value} name={name} type={type} className={styles.item_val} required={required} />
            ):(
                <input type={type} onChange={(e) => changeHandler(e)} onBlur={(e) => changeHandler(e)} className={styles.item_val} lang={base.currentLang} name={name} value={value} required={required}  />
            )}

            {typeof error !== 'undefined' ? (
                <span className={styles.item_error}>{t(error)}</span>
            ):null}
                  
            {errorType ? (
                <span className={styles.item_error}>{t(errorType)}</span>
            ):null}
        </div>
    );
};

export default Input