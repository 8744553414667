const Excerpt = (html, length, indicator) => {
    if(typeof html !== 'undefined' && html !== "" && html !== null) {
        const string = html.replace(/(<([^>]+)>)/ig, '');
        const listOfWords = string.trim().split(' ');
        const content = listOfWords.slice(0, length).join(' ') + indicator;

        return listOfWords.length > length ? content : string;
    } else {
        return html;
    }
};

export default Excerpt;
